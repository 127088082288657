import request from "umi-request";
import { logout } from "../08-Login/request";
export const ProductList = (params: any, id: any) =>
  request(`/api/v2/mallproduct/product/${id}`, {
    method: "get",
    params: params,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwtToken"),
    },
  })
    .then(function (response: any) {
      return response;
    })
    .catch(function (error: any) {
      if (error?.response?.status === 401) {
        logout();
      }
    });

// export const inventoryList = (params: any) =>
//     request(`/api/v1/inventory`, {
//         method: 'get',
//         params: params,
//     }).then(function (response: any) {
//         response.data.sort(lastsalecompare);
//         return response;
//     })
//         .catch(function (error: any) {
//             console.log(error);
//         });

export const getCategoryList = (params?: any) =>
  request(`/api/v2/mallproduct/category`, {
    method: "get",
    params: params,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwtToken"),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        logout();
      }
    });

export const skuList = (params: any) => {
  return request(`/api/v2/mallproduct/sku`, {
    method: "get",
    params: { ...params },
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwtToken"),
    },
  })
    .then(function (response: any) {
      return response;
    })
    .catch(function (error: any) {
      if (error?.response?.status === 401) {
        logout();
      }
    });
};
