import {
    Form,
    Button,
    Input,
    TextArea,
    DatePicker,
    Radio,
    Space,
    Toast,
} from 'antd-mobile'
import GoodsSelectList from './goods_picker';
import Big from 'big.js';
import './style.less';
// import { GetEmpolyee, GetWhs } from '../../../components/pages/order/orderForm/request';
import dayjs from 'dayjs';
// import { PostOrder, GetEmpolyee, GetWhs, PutOrder, GetBizPartner } from './request';
import { useParams } from 'react-router-dom';
import { useSessionStorageState } from 'ahooks';
import { createRef, useCallback, useEffect, useState } from 'react';
import { postOrder } from './request';
import { FormInstance } from 'antd-mobile/es/components/form';
const BigRM2 = Big();
BigRM2.RM = 2;

const demoData = [
    {
        "defwhs": true,
        "product_sku_id": 1915,
        "product_sku_name": "红酒",
        "sku_spec": "",
        "unit": "桶",
        "price": "20.000",
        "cost": "100.000",
        "quantity": 1,
        "amount": "20"
    },
    {
        "defwhs": true,
        "product_sku_id": 1578,
        "product_sku_name": "三合旺一滴香香油4.2L（桶）",
        "sku_spec": "4.2L",
        "unit": "桶",
        "price": "55.000",
        "cost": "50.000",
        "quantity": 1,
        "amount": "55"
    },
    {
        "defwhs": true,
        "product_sku_id": 1298,
        "product_sku_name": "李锦记排骨酱240g",
        "sku_spec": "240g*12瓶",
        "unit": "件",
        "price": "175.000",
        "cost": "172.000",
        "quantity": 1,
        "amount": "175.00"
    },
    {
        "defwhs": true,
        "product_sku_id": 1016,
        "product_sku_name": "南方莲花高纯味精908g",
        "sku_spec": "908g*10袋",
        "unit": "件",
        "price": "130.000",
        "cost": "120.000",
        "quantity": 1,
        "amount": "130.00"
    },
    {
        "defwhs": true,
        "product_sku_id": 351,
        "product_sku_name": "自立罗汉笋1.2kg（包）",
        "sku_spec": "1.2kg",
        "unit": "包",
        "price": "22.000",
        "cost": "17.820",
        "quantity": 1,
        "amount": "22.00"
    }
]



const totalcal = (array: any[]) => {
    //计算总额
    // console.log("======", array);
    if (array && array.length > 0) {
        return array
            .reduce(
                (total: any, item: any) =>
                    item.amount ? total.plus(item.amount) : total,
                BigRM2(0)
            )
            .toFixed(2);
    } else {
        return '0';
    }
};





let subtotal = ''

export default () => {

    const [form] = Form.useForm()
    const formRef = createRef<FormInstance>()
    let urlparams = useParams();
    const [submitting, setSubmitting] = useState(false);
    let now = new Date();
    now.setDate(now.getDate() + 7);

    const labelRenderer = useCallback((type: string, data: number) => {
        switch (type) {
            case 'year':
                return data + '年'
            case 'month':
                return data + '月'
            case 'day':
                return data + '日'
            case 'hour':
                return data + '时'
            case 'minute':
                return data + '分'
            case 'second':
                return data + '秒'
            default:
                return data
        }
    }, [])

    const [cartData, setCartData] = useSessionStorageState('local-cart');
    const [pickerVisible, setPickerVisible] = useState(false);

    //提交订单
    const onFinish = (values: any) => {
        setSubmitting(true);
        //transform data
        const subtotal = totalcal(values.products)
        const orderdata = {
            products: values.products,
            subtotal: subtotal,
            total: subtotal
        }
        console.log(orderdata)
        postOrder(orderdata).then(res => {
            if (res.status === 'success') {
                Toast.show({
                    icon: 'success',
                    content: '提交成功',
                })

                form?.resetFields();
                setCartData([]);
                // formRef?.current?.resetFields()
                // formRef?.current?.setFieldsValue({ delivery: 'selfpickup',products: cartData })
                setSubmitting(false);
            } else {
                if(res.status === 'error'){
                    Toast.show({
                        icon: 'fail',
                        content: res.message,
                    })
                }else{
                    Toast.show({
                        icon: 'fail',
                        content: '提交失败',
                    })
                }

                setSubmitting(false);
            }
        })
    }
    useEffect(() => {
        console.log('render form');
        form?.setFieldsValue({ products: cartData });
        // const actions = preventScrollBG('cart-list-container');
        // return () => {
        //     actions.removeListener();
        //   };
    }, [])
    return (
        <>
            <div id='cart-list-container'className='cart-list-container'>
                <Form
                    form={form}
                    ref={formRef}
                    // layout='horizontal'
                    // initialValues={
                    //     {
                    //         products: cartData
                    //     }
                    // }
                    onFinish={onFinish}
                    onValuesChange={(changedValues, allValues) => {
                        console.log(changedValues, allValues)
                        setCartData(allValues.products);
                    }}
                    footer={
                        <Button block loading={submitting} loadingText='提交中' key="submit" type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }
                >
                    <Form.Header>订单信息</Form.Header>
                    <Form.Item
                        name='products'
                        trigger='onChange'
                        noStyle
                        rules={[{ required: true, message: '请选择商品' }]}
                    >
                        <GoodsSelectList />
                    </Form.Item>
                    <Form.Item
                        shouldUpdate
                        noStyle
                    >
                        {({ getFieldValue }) => {
                            // const products = getFieldValue('products');
                            // subtotal = totalcal(products);

                            return (
                                <>
                                    <Form.Item name='subtotal' label="商品金额">
                                        <span>
                                            ¥ {subtotal}
                                        </span>
                                    </Form.Item>
                                </>
                            );
                        }}
                    </Form.Item>
                    <Form.Header>送货信息</Form.Header>
                    <Form.Item
                        name='name'
                        label='姓名'
                    >
                        <Input onChange={console.log} placeholder='请输入姓名' />
                    </Form.Item>
                    <Form.Item
                        name='delivery'
                        label='交付方式'
                    >
                        <Radio.Group>
                            <Space>
                                <Radio value='selfpickup'>到店自提</Radio>
                                <Radio value='delivery'>送货上门</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Subscribe to={['delivery']}>
                        {({ delivery }) => {
                            return (
                                <>
                                    {delivery === 'mobile' && (
                                        <Form.Item name='account' label='手机号'>
                                            <Input placeholder='请输入手机号' />
                                        </Form.Item>
                                    )}
                                    {delivery === 'delivery' && (
                                        <Form.Item name='address' label='地址' help='详情地址'>
                                            <TextArea
                                                placeholder='请输入地址'
                                                maxLength={100}
                                                rows={2}
                                                showCount
                                            />
                                        </Form.Item>
                                    )}
                                </>
                            )
                        }}
                    </Form.Subscribe>
                    <Form.Item
                        name='birthday'
                        label='预约时间'
                        trigger='onConfirm'
                        onClick={() => {
                            setPickerVisible(true)
                        }}
                    >
                        <DatePicker
                            min={new Date()}
                            max={now}
                            precision='hour'
                            visible={pickerVisible}
                            onClose={() => {
                                setPickerVisible(false)
                            }}
                            renderLabel={labelRenderer}
                        >
                            {value =>
                                value ? dayjs(value).format('YYYY-MM-DD HH:mm') : '请选择日期'
                            }
                        </DatePicker>
                    </Form.Item>
                    {/* <Form.Item
                        label='Form Instance'
                        shouldUpdate
                    >
                        {({ getFieldsValue }) => {
                            return JSON.stringify(getFieldsValue())
                        }}
                    </Form.Item>
                    <Form.Item
                        label='Session Storage'
                        shouldUpdate
                    >
                        {({ getFieldValue }) => {
                            return JSON.stringify(cartData)
                        }}
                    </Form.Item> */}
                </Form>
            </div>
        </>
    )
}