import dayjs from "dayjs";
import { Navigate, useLocation } from "react-router-dom";


function RequireAuth({ children }: { children: JSX.Element }) {
    let location = useLocation();
    const token =  localStorage.getItem('jwtToken');
    const expiredTime = localStorage.getItem('expiredTime');
    if (!token || dayjs(expiredTime).isBefore(dayjs())) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
}

export default RequireAuth;
