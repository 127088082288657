import { useEffect, useState } from 'react'
import { Card, Grid, Image, List, Space, Toast, Loading, Button, Dialog, NavBar } from 'antd-mobile'
import Big from 'big.js';
import './style.less';
import { useNavigate, useParams } from 'react-router-dom';
import { GetOrderFormById } from './request';
import { AddSquareOutline } from 'antd-mobile-icons'
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
// import { geturl, printpdf } from '../../../utils/pdfrul';
const classPrefix = 'order-detail-list';

const users = [
    {
        avatar:
            'https://images.unsplash.com/photo-1548532928-b34e3be62fc6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
        name: 'Novalee Spicer',
        description: 'Deserunt dolor ea eaque eos',
    },
    {
        avatar:
            'https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9',
        name: 'Sara Koivisto',
        description: 'Animi eius expedita, explicabo',
    },
    {
        avatar:
            'https://images.unsplash.com/photo-1542624937-8d1e9f53c1b9?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
        name: 'Marco Gregg',
        description: 'Ab animi cumque eveniet ex harum nam odio omnis',
    },
    {
        avatar:
            'https://images.unsplash.com/photo-1546967191-fdfb13ed6b1e?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ',
        name: 'Edith Koenig',
        description: 'Commodi earum exercitationem id numquam vitae',
    },
]
const onClick = () => {
    Toast.show('点击了卡片')
}
const testorderdata: any = {
    "productlist": [
        {
            "orderslineid": 11547,
            "ordersid": 2185,
            "defwhs": true,
            "product_sku_id": 761,
            "sku": null,
            "product_sku_name": "橙红",
            "sku_spec": null,
            "unit": "包",
            "quantity": "1.000",
            "price": "29.000",
            "amount": "29.000",
            "notes": null
        }
    ],
    "payments": [],
    "subtotal": "29.00",
    "totalamount": "29.00",
    "amountpaid": "0.00",
    "amountleft": "29.00",
    "ordersid": 2185,
    "bizpartnerid": 321,
    "warehouseid": 1,
    "employeeid": 1,
    "ordersnumber": "SO-211215042507",
    "orderstype": "SO",
    "ordersstatus": "open",
    "bizname": "都市先生车1Q56M",
    "created_time": "2021-12-15T08:24:54.000Z",
    "updated_time": "2021-12-15T08:25:07.000Z",
    "employeename": "小叶"
}

const OrderContent = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [orderdata, setData] = useState<any>({
        "productlist": [
        ],
        "payments": [],
        "subtotal": "",
        "totalamount": "",
        "amountpaid": "",
        "amountleft": "",
        "ordersid": "",
        "bizpartnerid": "",
        "warehouseid": "",
        "employeeid": "",
        "ordersnumber": "",
        "orderstype": "",
        "ordersstatus": "",
        "bizname": "",
        "created_time": "",
        "updated_time": "",
        "employeename": ""
    });
    let params = useParams();
    const navigate = useNavigate();
    useEffect(() => {

        setLoading(true);
        if (params.id !== undefined) {
            setTimeout(() => {
                GetOrderFormById(params.id).then(res => {
                    setData(res.data);
                    setLoading(false);
                })
            }, 500);

        }
    }, [params]);
    return (
        <>
            <NavBar onBack={()=>navigate(-1)}/>
            {loading ? <Loading /> :
                <div className={classPrefix + '-container'}>
                    <Space direction="vertical" block>
                        <Card title={props.orderstype} onClick={onClick} className='detail-list-title'>
                            <Space direction='vertical'>
                                {/* {params.orderId} */}
                                <span>单号: {orderdata?.ordernumber}</span>
                                {/* <span style={{ color: '#1677ff', fontWeight: 'bold' }}>
                                    客户: {orderdata.bizname}
                                </span> */}
                                <span>日期: {dayjs(orderdata?.created_time).format('YYYY-MM-DD HH:mm:ss')}</span>
                                {/* <span>经手人: {orderdata.employeename}</span>
                                <span>备注: {orderdata.ordersnote}</span> */}
                            </Space>
                        </Card>

                        <List style={{ overflow: 'visible' }}>
                            <List.Item
                            // extra='次要信息'
                            // title='订单商品'
                            // description='这里是描述信息'
                            // clickable
                            >
                                订单商品
                            </List.Item>
                            {orderdata?.products?.map((i: any, index: number) => {
                                return (
                                    <List.Item
                                        key={index}
                                        prefix={
                                            <Image
                                                src=''
                                                //   style={{ borderRadius: 20 }}
                                                fit='cover'
                                                width={80}
                                                height={80}
                                            />
                                        }
                                        // onClick={onClick}
                                        description={
                                            <Grid columns={3} >
                                                <Grid.Item span={2}>
                                                    <div >
                                                        规格: {i.sku_spec}
                                                    </div>
                                                </Grid.Item>
                                                <Grid.Item>
                                                    <div >
                                                        ¥ {Big(i.price).toFixed(2)}
                                                    </div>
                                                </Grid.Item>
                                                <Grid.Item span={3}>
                                                    <div >备注: {i.notes}</div>
                                                </Grid.Item>
                                                <Grid.Item >
                                                    <div >
                                                    </div>
                                                </Grid.Item>
                                                <Grid.Item span={2}>
                                                    <div className={'detail-list-amount'}>
                                                        金额: ¥ {Big(i.amount).toFixed(2)}
                                                    </div>
                                                </Grid.Item>
                                            </Grid>
                                        }
                                    >
                                        <Grid columns={3} >
                                            <Grid.Item span={2}>
                                                <div >
                                                    {i.product_sku_name}
                                                </div>
                                            </Grid.Item>
                                            <Grid.Item>
                                                <div >
                                                    {i.quantity} {i.unit}
                                                </div>
                                            </Grid.Item>
                                        </Grid>

                                    </List.Item>
                                )
                            })}
                        </List>
                        <Card >
                            <Button
                                block
                                color='default'
                                size='middle'
                                onClick={()=>{
                                    Toast.show('开发中...');
                                }}
                            >
                                <Space>
                                    <AddSquareOutline />
                                    <span>追加商品</span>
                                </Space>

                            </Button>
                        </Card>

                        <Card onClick={onClick} >
                            <Grid columns={2} >
                                <Grid.Item >
                                    <div className='detail-list-title'>
                                        商品金额:
                                    </div>
                                </Grid.Item>
                                <Grid.Item >
                                    <div className='detail-list-summary'>
                                        {orderdata?.subtotal}
                                    </div>
                                </Grid.Item>
                                <Grid.Item >
                                    <div className='detail-list-title'>
                                        运输费:
                                    </div>
                                </Grid.Item>
                                <Grid.Item >
                                    <div className='detail-list-summary'>
                                        {orderdata?.freight ? orderdata.freight : '0.00'}
                                    </div>
                                </Grid.Item>
                                <Grid.Item >
                                    <div className='detail-list-title'>
                                        优惠金额:
                                    </div>
                                </Grid.Item>
                                <Grid.Item>
                                    <div className='detail-list-summary'>
                                        {orderdata?.discount ? orderdata.discount : '0.00'}
                                    </div>
                                </Grid.Item>
                                <Grid.Item >
                                    <div className='detail-list-title'>
                                        总金额:
                                    </div>
                                </Grid.Item>
                                <Grid.Item>
                                    <div className='detail-list-summary'>
                                        {orderdata?.total}
                                    </div>
                                </Grid.Item>
                                <Grid.Item >
                                    <div className='detail-list-title'>
                                        已付金额:
                                    </div>
                                </Grid.Item>
                                <Grid.Item>
                                    <div className='detail-list-summary'>
                                        {orderdata?.paidamount ? orderdata.paidamount : '0.00'}
                                    </div>
                                </Grid.Item>
                                <Grid.Item >
                                    <div className='detail-list-title'>
                                        订单状态:
                                    </div>
                                </Grid.Item>
                                <Grid.Item>
                                    <div className='detail-list-summary'>
                                        {orderdata?.status ? orderdata.status : ''}
                                    </div>
                                </Grid.Item>
                            </Grid>
                        </Card>
                        <Card >
                            <Button
                                block
                                color='warning'
                                size='middle'
                                onClick={() => {
                                    Dialog.show({
                                        content: '是否确认取消订单',
                                        closeOnAction: true,
                                        actions: [
                                            // {
                                            //   key: 'online',
                                            //   text: '直接取消',
                                            // },
                                            // {
                                            //   key: 'download',
                                            //   text: '放回购物车并取消',
                                            // },
                                            [
                                                {
                                                    key: 'cancel',
                                                    text: '取消',
                                                },
                                                {
                                                    key: 'delete',
                                                    text: '确认',
                                                    bold: true,
                                                    danger: true,
                                                },
                                            ],
                                        ],
                                    })
                                }}
                            >
                                取消订单
                            </Button>
                        </Card>
                    </Space>
                </div>
            }
        </>
    )
}

export default OrderContent;