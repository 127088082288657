/**
 * prevent background dom element scroll, to minimize scrolling not responding to user issue
 * @param elementid id of the element to prevent scroll
 */
export const preventScrollBG = (elementid: string) => {
  let initialPageY: number;
  const scrollBox = document.getElementById(elementid);

  const preventScrollUp = (e: any) => {
    initialPageY = e?.changedTouches[0].pageY;
  };

  const preventScrollDown = (e: any) => {
    const deltaY = e.changedTouches[0].pageY - initialPageY;
    if (scrollBox) {
      // 禁止向上滚动溢出
      if (e.cancelable && deltaY > 0 && scrollBox.scrollTop <= 0) {
        e.preventDefault();
      }

      // 禁止向下滚动溢出
      if (
        e.cancelable &&
        deltaY < 0 &&
        scrollBox.scrollTop + scrollBox.clientHeight >= scrollBox.scrollHeight
      ) {
        e.preventDefault();
      }
    }
  };
  scrollBox?.addEventListener("touchstart", preventScrollUp);

  scrollBox?.addEventListener("touchmove", preventScrollDown);

  const removeListener = () => {
    scrollBox?.removeEventListener("touchstart", preventScrollUp);
    scrollBox?.removeEventListener("touchmove", preventScrollDown);
    // console.log("remove preventScrollBG Listener");
  }
  return { removeListener };
};
