import { Button, Form } from 'antd-mobile';
import { ButtonProps } from 'antd-mobile/es/components/button';
import { FormInstance, NamePath } from 'antd-mobile/es/components/form';
// import type { NamePath } from 'antd/lib/form/interface';
import React, { useEffect, useImperativeHandle, useState } from 'react';

export type FieldProps<K> = {
    style?: React.CSSProperties;
    width?: string;
    ref?: React.Ref<K>;
  };
export type ProFormFieldItemProps<T = Record<string, any>, K = any> = {
    /**
     * @name 设置到控件上的属性
     *
     * @example 设置select 多选
     * <ProFormSelect fieldProps={{mode:"multiple"}} />
     * @example 设置select 多选
     * <ProFormText fieldProps={{placeholder:"请输入！"}} />
     */
    fieldProps?: FieldProps<K> & T;
    /**
     * @name 输入的描述，没有值的时候展示
     */
    placeholder?: string | string[];
    /**
     * @name 是否是次要控件，只针对 LightFilter 下有效
     */
    secondary?: boolean;
    /**
     * @name 是否使用 swr 来缓存 缓存可能导致数据更新不及时，请谨慎使用，尤其是页面中多个组件 name 相同
     *
     * @default false
     */
    cacheForSwr?: boolean;
    /**
     * @name disabled=true 时控件不可用
     */
    disabled?: boolean;
    /**
     * @type auto 使用组件默认的宽度
     * @type xs=104px 适用于短数字、短文本或选项。
     * @type sm=216px 适用于较短字段录入、如姓名、电话、ID 等。
     * @type md=328px 标准宽度，适用于大部分字段长度。
     * @type lg=440px 适用于较长字段录入，如长网址、标签组、文件路径等。
     * @type xl=552px 适用于长文本录入，如长链接、描述、备注等，通常搭配自适应多行输入框或定高文本域使用。
     */
    width?: number | 'sm' | 'md' | 'xl' | 'xs' | 'lg';
  
    children?: React.ReactNode;
  }

    
export type ProFormCaptchaProps =  {
  /** @name FormInstance */
  form: FormInstance;
  /** @name 倒计时的秒数 */
  countDown?: number;

  /** 手机号的 name */
  phoneName?: NamePath;

  /** @name 获取验证码的方法 */
  onGetCaptcha: (mobile: string) => Promise<void>;

  /** @name 渲染按钮的文字 */
  captchaTextRender?: (timing: boolean, count: number) => React.ReactNode;

  /** @name 获取按钮验证码的props */
  captchaProps?: ButtonProps;

  value?: any;
  onChange?: any;
};

export type CaptFieldRef = {
  startTiming: () => never;
  endTiming: () => never;
};

const ProFormCaptcha: React.FC<ProFormCaptchaProps> = React.forwardRef((props, ref: any) => {
  // const [form] = Form.useForm();
  const [count, setCount] = useState<number>(props.countDown || 60);
  const [timing, setTiming] = useState(false);
  const [loading, setLoading] = useState<boolean>();
  // 这么写是为了防止restProps中 带入 onChange, defaultValue, rules props tabUtil
  const {
    form,
    phoneName,
    captchaTextRender = (paramsTiming, paramsCount) => {
      return paramsTiming ? `${paramsCount} 秒后重新获取` : '获取验证码';
    },
    captchaProps,
    ...restProps
  } = props;

  const onGetCaptcha = async (mobile: string) => {
    try {
      setLoading(true);
      await restProps.onGetCaptcha(mobile);
      setLoading(false);
      setTiming(true);
    } catch (error) {
      setTiming(false);
      setLoading(false);
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  /**
   * 暴露ref方法
   */
  useImperativeHandle(ref, () => ({
    startTiming: () => setTiming(true),
    endTiming: () => setTiming(false),
  }));

  useEffect(() => {
    let interval: number = 0;
    const { countDown } = props;
    if (timing) {
      interval = window.setInterval(() => {
        setCount((preSecond) => {
          if (preSecond <= 1) {
            setTiming(false);
            clearInterval(interval);
            // 重置秒数
            return countDown || 60;
          }
          return preSecond - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timing]);
  return (
    <>
      <Button
        style={{
          display: 'block',
        }}
        fill='none'
        disabled={timing}
        loading={loading}
        {...captchaProps}
        onClick={async () => {
          try {
            if (phoneName) {
              console.log('phoneName')
              await form.validateFields([phoneName].flat(1) as string[]);
              const mobile = form.getFieldValue([phoneName].flat(1) as string[]);
              await onGetCaptcha(mobile);
            } else {
              await onGetCaptcha('');
            }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            
          }
        }}
      >
        {captchaTextRender(timing, count)}
      </Button>
      </>
  );
});


export default ProFormCaptcha;