import { useLocalStorageState } from "ahooks";
import { Form, Input, Image, Button, Grid, Toast, Checkbox, Tabs } from "antd-mobile"
import { Input as AntInput } from 'antd';
import dayjs from "dayjs";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.png";
import { getAuth, getSmsCode, login } from "./request";
import './style.less'
import PhoneInput, { MobileValue } from "./phoneInput";
import ProFormCaptcha from "./captcha";
const classPrefix = 'mall-login'

// function RequireAuth({ children }: { children: JSX.Element }) {
//     let auth = useAuth();
//     let location = useLocation();

//     if (!auth.user) {
//       // Redirect them to the /login page, but save the current location they were
//       // trying to go to when they were redirected. This allows us to send them
//       // along to that page after they login, which is a nicer user experience
//       // than dropping them off on the home page.
//       return <Navigate to="/login" state={{ from: location }} replace />;
//     }

//     return children;
//   }


const Login = () => {
    const [token, setToken] = useLocalStorageState('jwtToken', {
        serializer: (v: string) => v,
        deserializer: (v: string) => v,
    });
    const [expiredTime, setExpiredTime] = useLocalStorageState('expiredTime');
    const [profile, setProfile] = useLocalStorageState<any>('userProfile')
    const navigate = useNavigate();
    //find from location
    const location = useLocation();
    const state = location.state as { from: Location }
    const { from } = state || { from: { pathname: "/mall" } };
    const [form] = Form.useForm()
    const mobile = Form.useWatch('mobile', form);

    const onFinish = async (values: any) => {
        const { username, password, mobile, captcha } = values;
        const phone = mobile ? `+${mobile.code}${mobile.phone}` : null;
        const payload = {
            username,
            password,
            phone,
            captcha,
        }
        const res = await login(payload);
        if (res.user && res.token) {
            setToken(res.token.accessToken);
            setExpiredTime(dayjs().add(res.token.expiresIn, 'second').format('YYYY-MM-DD HH:mm:ss'));
            setProfile(res.user)
            navigate(from, { replace: true })
        }
    }
    const onSendCode = async (values: any) => {
        console.log('code')
        Toast.show('发送验证码成功')
    }
    const checkPhoneNumber = (_: any, value: MobileValue) => {
        if (value?.phone) {
            // return Promise.resolve()
            if (value?.phone.match(/^1\d{10}$/)) {
                return Promise.resolve()
            }
            return Promise.reject(new Error('输入正确的手机号'))
        }
        return Promise.reject(new Error('手机号不能为空'))
    }
    useEffect(() => {
        console.log('login mount')
        if (token) {
            getAuth().then(res => {
                if (res.user) {
                    Toast.show('登录成功')
                    navigate(from, { replace: true })
                } else {
                    setToken('')
                }
            })
        }
    }, [])
    return (
        <>
            <div className={classPrefix + '-container'}>
                <div className={classPrefix + "-logo"}>
                    <Image width={100} height={100} src={logo} />
                </div>
                {/* <input value={token} readOnly></input> */}
                <Form
                    form={form}
                    layout='vertical'
                    initialValues={{
                        loginMethod: 'mobile',
                    }}
                    onFinish={onFinish}
                    footer={
                        <>
                            {/* <div style={{
                                marginBottom: '24px',
                                fontSize: '15px',
                                color: 'var(--adm-color-weak)',
                                textAlign: 'center'
                            }}>
                                <span
                                >
                                    登陆即代表您已阅读并同意<a>服务协议</a>和<a>隐私协议</a>
                                </span>
                            </div> */}
                            <Button
                                block
                                color='primary'
                                type='submit'
                                onClick={() => {
                                    // this.formRef.current?.resetFields()
                                }}
                                size='large'
                            >
                                登陆
                            </Button>

                        </>
                    }
                    style={{
                        '--border-top': '0px'
                    }}
                >

                    <Form.Item name='loginMethod' valuePropName='activeKey' noStyle>
                        <Tabs>
                            <Tabs.Tab title='手机号登陆' key='mobile'/>
                            <Tabs.Tab title='账号密码登录' key='email'/>
                        </Tabs>
                    </Form.Item>
                    <Form.Subscribe to={['loginMethod']} >
                        {({ loginMethod }) => {
                            return (
                                <>
                                    {loginMethod === 'mobile' && (
                                        <>
                                            <Form.Item
                                                label='手机号'
                                                name='mobile'
                                                className="login"
                                                rules={[
                                                    { required: true, message: '' },
                                                    { validator: checkPhoneNumber }

                                                ]}
                                            >
                                                <PhoneInput placeholder='请输入手机号' />
                                            </Form.Item>
                                            <Form.Item
                                                label='短信验证码'
                                                name='captcha'
                                                rules={[
                                                    { required: true, message: '验证码不能为空' },
                                                    { type: 'string', len: 6 },
                                                ]}
                                                extra={
                                                    <div >
                                                        <ProFormCaptcha
                                                            form={form}
                                                            phoneName='mobile'
                                                            countDown={10}
                                                            onGetCaptcha={async () => {
                                                                // console.log('captcha', phoneNumber)
                                                                const smsCode = await getSmsCode(mobile);
                                                                if (smsCode?.statusCode > 400) {
                                                                    Toast.show(smsCode.error);
                                                                    throw new Error("获取验证码错误")
                                                                } else {
                                                                    Toast.show({ icon: 'success', content: `手机号 ${mobile.phone} 验证码发送成功!` });
                                                                }
                                                            }
                                                            } />
                                                    </div>
                                                }
                                            >
                                                <Input placeholder='请输入验证码' clearable />
                                            </Form.Item>
                                        </>
                                    )}
                                    {loginMethod === 'email' && (
                                        <>
                                            <Form.Item
                                                label='用户名'
                                                name='username'
                                                rules={[
                                                    { required: true, message: '用户名不能为空' },
                                                    { type: 'string', min: 6 },
                                                ]}
                                            >
                                                <Input placeholder='请输入用户名' clearable />
                                            </Form.Item>
                                            <Form.Item
                                                label='密码'
                                                name='password'
                                                rules={[
                                                    { required: true, message: '密码不能为空' },
                                                    { type: 'string', min: 6 },
                                                ]}
                                            >
                                                <Input placeholder='请输入密码' clearable type='password' />
                                            </Form.Item>
                                        </>)}
                                </>
                            )
                        }}
                    </Form.Subscribe>

                    {/*  */}
                </Form>

            </div>
        </>
    )
}

export default Login;