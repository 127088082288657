import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

type user = {
      userid?: number,
      username?: string,
      realname?: string,
      accesslevel?: string,
      userstatus?: string,
      expirydate?: string,
      email?: string,
      phone?: string,
      favsettings?: any,
      employeeid?: string,
      whsid?: string,
      created_time?: string,
      updated_time?: string
  }

export interface SessionState {
  userid?: number,
  username?: string,
  accesslevel?: string,
  profile?: user
}

const initialState: SessionState = {
  userid: undefined,
  username: '',
  accesslevel: '',
  profile: {},
};



export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    userlogin: (state, action: PayloadAction<SessionState>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      
      state.userid = action.payload.userid;
      state.username = action.payload.username;
      state.accesslevel = action.payload.accesslevel;
      state.profile = action.payload.profile;

    },
    userlogout: (state, action: PayloadAction<any>) => {
      state.userid = initialState.userid;
      state.username = initialState.username;
      state.accesslevel = initialState.accesslevel;
      state.profile = initialState.profile;
    },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = 'idle';
  //       state.value += action.payload;
  //     });
  // },
});

export const { userlogin, userlogout } = sessionSlice.actions;

export const selectSession= (state: RootState) => state.session;

export default sessionSlice.reducer;