import { useLocalStorageState, useSessionStorageState } from "ahooks";
import { Button, ErrorBlock } from "antd-mobile";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { tempQRCodelogin } from "./request";


export const QRLoginPage = () => {
    const [token, setToken] = useLocalStorageState('jwtToken', {
        serializer: (v: string) => v,
        deserializer: (v: string) => v,
    });
    const [profile, setProfile] = useLocalStorageState<any>('userProfile')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate();
    let params = useParams();

    useEffect(() => {
        setLoading(true);
        tempQRCodelogin(params.id).then((res: any) => {
            setLoading(false);
            if (res.user && res.token) {
                setToken(res.token.accessToken)
                setProfile(res.user)
                navigate('/', { replace: true })
            }
        }).catch((error) => {
            setLoading(false);
            setError('Invalid QR Code')
        })
    }, [])

    return (
        <>
            {loading && <span>
                登陆中
            </span>}
            {error && <ErrorBlock status='empty' description={error} fullPage>
                <Button onClick={() => navigate('/')} color='primary'>首页</Button>
            </ErrorBlock>}
        </>
    )
}