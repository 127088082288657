import { Avatar, Badge, Card, Grid, Space } from "antd-mobile"
import {
    ContentOutline,
    UndoOutline,
    AppstoreOutline,
    SystemQRcodeOutline
} from 'antd-mobile-icons'
import { ShoppingCartOutlined, WalletOutlined, CustomerServiceOutlined, SettingOutlined, HomeOutlined, CreditCardOutlined } from '@ant-design/icons'
import './style.less'
import { useLocalStorageState } from "ahooks"
import { logout } from "../08-Login/request"
import { useNavigate } from "react-router-dom"
import { logo } from "../../../assets"
const classPrefix = 'account'

const tabs = {
    orders: [
        {
            key: '/mall/order?index=1',
            title: '待付款',
            icon:
                <Badge content='1' style={{ '--right': '-10px', '--top': '8px' }}>
                    <CreditCardOutlined color='#76c6b8' />
                </Badge>
            ,
        },
        {
            key: '/mall/order?index=2',
            title: '处理中',
            icon: <ShoppingCartOutlined />,
        },
        {
            key: '/mall/order?index=3',
            title: '已完成',
            icon: <HomeOutlined />,
        },
        {
            key: '/mall/underdev',
            title: '退款/售后',
            icon: <UndoOutline />,
        },
        {
            key: '/mall/order?index=0',
            title: '全部订单',
            icon: <WalletOutlined />,
        },
    ],
    main: [
        {
            key: '/underdev',
            title: '联系客服',
            icon: <CustomerServiceOutlined />,
        },
        {
            key: '/underdev',
            title: '我的账单',
            icon: <ContentOutline color='#76c6b8' />,
        },
        {
            key: '/underdev',
            title: '常见问题',
            icon: <AppstoreOutline />,
        },
        {
            key: '/underdev',
            title: '设置',
            icon: <SettingOutlined />,
        },
    ],
    admin: [
        {
            key: '/qrcode',
            title: '扫码登陆',
            icon: <SystemQRcodeOutline />,
        },
    ]

}

export default () => {
    const navigate = useNavigate()
    const onClick = (key: string) => {
        navigate(key)
    }
    const [profile, setProfile] = useLocalStorageState<any>('userProfile')
    return (
        <>
            <div className="account-backgroud">
                <Grid columns={1} gap={0}>
                    <Grid.Item>
                        <div className={'profile-block'}>
                            <Space >
                                <Avatar style={{ '--size': '64px' }} src={''} />
                                {profile ?
                                    <p style={{ fontSize: '24px' }}>
                                        {profile?.username}
                                        <br/>
                                        <a onClick={() => logout()}>logout</a>
                                    </p>
                                    : <p style={{ fontSize: '24px' }}>
                                        <a href="/login">登陆</a>/<a href="/register">注册</a>
                                    </p>}
                            </Space>
                        </div>
                    </Grid.Item>
                    <Grid.Item>
                        <Card title='我的订单' >
                            <Grid columns={5}>
                                {tabs.orders.map((item, index) => (
                                    <Grid.Item key={index} className={classPrefix + '-mobile-item'} onClick={() => onClick(item.key)}>
                                        <div className={classPrefix + '-mobile-icon'}>
                                            {item.icon}
                                        </div>
                                        <div className={classPrefix + '-mobile-label'}>{item.title}</div>
                                    </Grid.Item>
                                ))}
                            </Grid>
                        </Card>

                    </Grid.Item>
                    <Grid.Item>
                        <Card title='功能和服务' >
                            <Grid columns={4}>
                                {tabs.main.map((item, index) => (
                                    <Grid.Item key={index} className={classPrefix + '-mobile-item'} onClick={() => onClick(item.key)}>
                                        <div className={classPrefix + '-mobile-icon'}>
                                            {item.icon}
                                        </div>
                                        <div className={classPrefix + '-mobile-label'}>{item.title}</div>
                                    </Grid.Item>
                                ))}
                            </Grid>
                        </Card>
                    </Grid.Item>
                    {profile?.role === 'ADMIN' && <Grid.Item>
                        <Card title='管理员功能' >
                            <Grid columns={4}>
                                {tabs.admin.map((item, index) => (
                                    <Grid.Item key={index} className={classPrefix + '-mobile-item'} onClick={() => onClick(item.key)}>
                                        <div className={classPrefix + '-mobile-icon'}>
                                            {item.icon}
                                        </div>
                                        <div className={classPrefix + '-mobile-label'}>{item.title}</div>
                                    </Grid.Item>
                                ))}
                            </Grid>
                        </Card>
                    </Grid.Item>}
                </Grid>

            </div>
        </>
    )
}