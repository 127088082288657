import { Toast } from "antd-mobile";
import request from "umi-request";
import { MobileValue } from "./phoneInput";
/**
 * login request
 */
export const login = (params: any) =>{
  if(params.password){
    return request("/api/v2/auth/login", {
      method: "POST",
      data: params,
    })
      .then((res) => {
        Toast.show(res.message);
        return res;
      })
      .catch((err) => Toast.show(err.data.message));
  }
  return request("/api/v2/auth/captchalogin", {
    method: "POST",
    data: params,
  })
    .then((res) => {
      Toast.show(res.message);
      return res;
    })
    .catch((err) => Toast.show(err.data.message));

}


export const getAuth = () =>
  request("/api/v2/auth/profile", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwtToken"),
    },
  })
    .then((res) => res)
    .catch((err) => err);

export const logout = () => {
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("userProfile");
  window.location.href = "/login";
  return Promise.resolve();
};

export const getSmsCode = (data: MobileValue) => request('/api/v2/auth/getcode', {
  method: 'post',
  data: {...data}
}).then(function (response) {
  return (response)
})
  .catch((error) => {
      if (error?.data) {
          return (error.data);
      }
      console.log(error);
  });