import { Image, Toast, Ellipsis, SearchBar, Empty, Space, Grid, InfiniteScroll, DotLoading, NavBar, ImageViewer } from "antd-mobile"
import { ReactFragment, ReactPortal, useEffect, useRef, useState } from "react"
import Collapse from "./collapse";
// import DesenseText from "../../utils/desense-text"
import './style.less'
import { skuList } from "./request"
// import { localizations } from "../../language/cn_zh"
// import NavBarBack from "../../navbarback"
import { useNavigate } from "react-router-dom"
import AddToCart from "./add_to_cart"
import useUrlState from "@ahooksjs/use-url-state";
import { SearchBarRef } from "antd-mobile/es/components/search-bar";

const localizations = {
    loadmore: '加载更多',
    loadingText: '加载中'
}
const products: any = []
const classPrefix = `sku-list`
let current = 0;
const SKUList = (props: any) => {
    const navigate = useNavigate()
    const searchRef = useRef<SearchBarRef>(null)
    const [skus, setSKUs] = useState(products)
    const [isEmpty, setIsEmpty] = useState(false)
    const [morePages, setMorePages] = useState(true)
    const [urlParams, setUrlParams] = useUrlState({ pageSize: '15', keyWord: '辣椒' }, {
        navigateMode: 'replace'
    })

    async function loadMore(keyWord?: string) {
        console.log('loadmoresku', urlParams, keyWord)
        current++
        if (keyWord) {
            const { data } = await skuList({ ...urlParams, current: current, keyWord: keyWord })
            if (current === 1 && data.length === 0) {
                setSKUs([])
                setMorePages(false)
                setIsEmpty(true)
            }
            setSKUs((val: any) => [...val, ...data])
            setMorePages(data.length > 0)
        } else {
            const { data } = await skuList({ ...urlParams, current: current })
            setSKUs((val: any) => [...val, ...data])
            setMorePages(data.length > 0)
        }
    }

    function onSearch(keyWord?: string) {
        if (keyWord) {
            console.log('search', keyWord)
            current = 0;
            setSKUs([])
            setMorePages(true)
            setUrlParams((state) => ({ keyWord: keyWord }))
            loadMore(keyWord)
        } else {
            setSKUs([])
            setMorePages(false)
            setIsEmpty(true)
        }
    }

    useEffect(() => {
        onSearch(urlParams.keyWord)
    }, [])
    return (
        <>
            <div className={classPrefix + '-header'}>
                <div className={'left'}>
                    <NavBar
                        style={{
                            '--height': 'inherit',
                        }}
                        onBack={() => {
                            navigate(-1)
                        }}
                    >
                        <SearchBar
                            ref={searchRef}
                            defaultValue={urlParams.keyWord}
                            placeholder="请输入商品名"
                            showCancelButton
                            style={{
                                // "--background": "#ffffff",
                                padding: "15 12 15 12",
                            }}
                            onSearch={(val) => {
                                Toast.show(`你搜索了：${val}`);
                                onSearch(val)
                            }}
                            onFocus={() => {
                                Toast.show("获得焦点");
                            }}
                            onBlur={() => {
                                Toast.show("失去焦点");
                            }}
                            onClear={() => {
                                Toast.show("清空内容");
                                onSearch()
                            }}
                            onCancel={() => {
                                Toast.show("取消搜索");
                                // onSearch()
                            }}

                        />
                    </NavBar>
                </div>
                {/* <div className={'right'}>

                </div> */}
            </div>
            {skus.length > 0 ? (
                <div className={classPrefix + '-container'}>
                    <Collapse
                        style={{ '--padding-left': '6px', '--padding-right': '6px' }}
                    >
                        {skus.map((item: {
                            image: string;
                            images: string[];
                            product_spu_name: string;
                            spu_spec: string;
                            saleprice: string;
                            children: { map: (arg0: (child: { product_sku_name: string; sku_spec: string | null | undefined; saleprice: string | number; unit: string | null | undefined; }, index: string) => JSX.Element) => string | number | null | undefined; };
                        }, index: any) => (
                            <Collapse.Panel
                                className={classPrefix + "-list-item"}
                                key={String(index)}
                                arrow={false}
                                // clickable={false}
                                prefix={
                                    <Image
                                    src={item?.images?.[0] || item?.image}
                                        //   style={{ borderRadius: 20 }}
                                        onClick={() => {
                                            ImageViewer.Multi.show({ images: item?.images })
                                          }}
                                          fit='contain'
                                          lazy={true}
                                        width={100}
                                        height={100}
                                    />
                                }
                                title={
                                    <>
                                        <Grid columns={3} gap={0}>
                                            <Grid.Item span={3}>
                                                <Ellipsis
                                                    className={classPrefix + "-product-spu-name"}
                                                    direction="end"
                                                    rows={2}
                                                    content={item?.product_spu_name ? item?.product_spu_name : " "}
                                                />
                                            </Grid.Item>
                                            <Grid.Item span={3}>
                                                <Ellipsis
                                                    className={classPrefix + "-product-spu-spec"}
                                                    direction="end"
                                                    rows={2}
                                                    content={item?.spu_spec ? item?.spu_spec : "规格"}
                                                />
                                            </Grid.Item>
                                            <Grid.Item span={3}>
                                                <Ellipsis
                                                    className={classPrefix + "-price"}
                                                    direction="end"
                                                    rows={1}
                                                    content={item?.saleprice ? item?.saleprice : "价格"}
                                                />
                                            </Grid.Item>
                                        </Grid>
                                    </>
                                }
                            >
                                <Space direction='vertical' block>
                                    {item?.children.map((child: { product_sku_name: string; sku_spec: boolean | ReactFragment | ReactPortal | null | undefined; saleprice: string | number | null | undefined; unit: boolean | ReactFragment | ReactPortal | null | undefined }, index: string) => {
                                        return (
                                            <Grid key={'sku_name' + index} columns={12} gap={8}>
                                                <Grid.Item span={4}>
                                                    <Ellipsis
                                                        className={classPrefix + "-product-sku-name"}
                                                        direction="end"
                                                        rows={2}
                                                        content={child?.product_sku_name ? child?.product_sku_name : " "}
                                                    />
                                                </Grid.Item>
                                                <Grid.Item key={'sku_spec' + index} span={3}>
                                                    <div className={classPrefix + '-product-sku-spec'}>{child?.sku_spec}</div>
                                                </Grid.Item>
                                                <Grid.Item key={'unit' + index} span={3}>
                                                    <div className={classPrefix + '-product-sku-unit'}>{child?.saleprice}/{child?.unit}</div>
                                                </Grid.Item>
                                                <Grid.Item key={'cart' + index} span={2}>
                                                    <div >
                                                        <AddToCart
                                                            product={child}
                                                            value={props.value}
                                                            onChange={props.onChange}
                                                        /></div>
                                                </Grid.Item>
                                            </Grid>
                                        )
                                    })
                                    }
                                </Space>
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                    <InfiniteScroll loadMore={()=>loadMore()} hasMore={morePages} />
                </div>
            ) : isEmpty ?
                <Empty
                    style={{ padding: '64px 0', width: '100%' }}
                    imageStyle={{ width: 128 }}
                    description='暂无数据'
                />
                : (
                    <div className={classPrefix + '-placeholder'}>
                        <div className={'loadingWrapper'}>
                            <DotLoading />
                        </div>
                        正在拼命加载数据
                    </div>
                )}

        </>
    )
}

export default SKUList;