import useUrlState from "@ahooksjs/use-url-state";
import { Input as MInput, NavBar, Swiper, Tabs, Toast } from "antd-mobile"
import { Space } from "antd";
import { SearchOutline } from 'antd-mobile-icons';
import { SwiperRef } from "antd-mobile/es/components/swiper";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import MobileOrderList from "./order_list/list";
import './style.less';

const classPrefix = 'mall-order-history'

const tabItems = [
    { key: 'all', title: '全部' },
    { key: 'pending', title: '待付款' },
    { key: 'progress', title: '处理中' },
    { key: 'archived', title: '已完成' },
    { key: 'canceled', title: '已取消' },
]

const OrderTabs = () => {
    const [key, setSearchKey] = useState<string>('progress')
    const [urlState, setUrlState] = useUrlState({ index: undefined }, { navigateMode: 'replace' });
    const swiperRef = useRef<SwiperRef>(null)
    const [activeIndex, setActiveIndex] = useState(() => { return urlState?.index ? urlState?.index : 0 })
    const navigate = useNavigate();

    function onBack() {
        navigate(-1);
    }
    function onPressEnterKey(e: React.KeyboardEvent<HTMLInputElement>) {
        e.preventDefault();
        Toast.show('search')
    }
    useEffect(() => {
        setUrlState({ index: activeIndex })
    }, [activeIndex])

    useEffect(() => {
        if (urlState.index) {
            setActiveIndex(urlState?.index ? urlState?.index : 0)
            swiperRef.current?.swipeTo(urlState?.index ? urlState?.index : 0)
        }
    }, [urlState.index])
    return (
        <div className={classPrefix + "-background"}>
            <NavBar onBack={onBack} style={{ '--border-bottom': '1px var(--adm-border-color) solid' }}>
                <Space>
                    <SearchOutline />
                    <MInput onEnterPress={onPressEnterKey} placeholder='搜索我的订单' clearable />
                </Space>
            </NavBar>
            <Tabs
                // onChange={(key)=> setSearchKey(key)} 
                activeKey={tabItems[activeIndex].key}
                onChange={key => {
                    const index = tabItems.findIndex(item => item.key === key)
                    setActiveIndex(index)
                    swiperRef.current?.swipeTo(index)
                }}
            >
                {tabItems.map(item => (
                    <Tabs.Tab title={item.title} key={item.key} />
                ))}
            </Tabs>
            <Swiper
                direction='horizontal'
                loop
                indicator={() => null}
                ref={swiperRef}
                defaultIndex={activeIndex}
                onIndexChange={index => {
                    setActiveIndex(index)
                }}
            >
                <Swiper.Item>
                    <div
                        className={'contentFull'}
                        style={{
                            // background: '#ace0ff', 
                            height: window.innerHeight - 51
                        }}
                    >
                        <MobileOrderList status='all' />
                    </div>
                </Swiper.Item>
                <Swiper.Item>
                    <div
                        className={'contentFull'}
                        style={{
                            // background: '#ace0ff', 
                            height: window.innerHeight - 51
                        }}
                    >
                        <MobileOrderList status='pending' />
                    </div>
                </Swiper.Item>
                <Swiper.Item>
                    <div
                        className={'contentFull'}
                        style={{
                            // background: '#ace0ff', 
                            height: window.innerHeight - 51
                        }}
                    >
                        <MobileOrderList status='progress' />
                    </div>
                </Swiper.Item>
                <Swiper.Item>
                    <div
                        className={'contentFull'}
                        style={{
                            // background: '#ace0ff', 
                            height: window.innerHeight - 51
                        }}
                    >
                        <MobileOrderList status='archived' />
                    </div>
                </Swiper.Item>
                <Swiper.Item>
                    <div
                        className={'contentFull'}
                        style={{
                            // background: '#ace0ff', 
                            height: window.innerHeight - 51
                        }}
                    >
                        <MobileOrderList status='canceled' />
                    </div>
                </Swiper.Item>
            </Swiper>
        </div>
    )
}

export default OrderTabs;