import request from "umi-request";
import { logout } from "../../08-Login/request";
export const GetOrders = (params: {}) => {
  return request("/api/v2/mallorder", {
    method: "get",
    params: params,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwtToken"),
    },
  })
    .then(async function (response) {
      console.log(response);
      return response;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        logout();
      }
    });
};
