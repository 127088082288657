import { useRef, useState } from 'react'
import { Popup, SearchBar, Toast, NavBar } from 'antd-mobile'
import { SearchBarRef } from 'antd-mobile/es/components/search-bar'
import './style.less'
import { useNavigate } from 'react-router-dom'

const classPrefix = 'mall-search'
export default () => {
    const [visible4, setVisible4] = useState(false)
    const searchRefMain = useRef<SearchBarRef>(null)
    const searchRefPop = useRef<SearchBarRef>(null)
    const navigate = useNavigate()
    return (
        <>
            <SearchBar
                ref={searchRefMain}
                placeholder="搜索商品"
                style={{
                    // "--background": "#ffffff",
                    padding: "15 12 15 12",
                }}
                onSearch={(val) => {
                    Toast.show(`你搜索了：${val}`);
                    navigate(`/mall/search?keyWord=${val}`);
                }}
                onFocus={() => {
                    // Toast.show("获得焦点");
                    // setVisible4(true)
                }}
                onBlur={() => {
                    searchRefMain?.current?.clear();
                }}
            />
            <Popup
                visible={visible4}
                onMaskClick={() => {
                    setVisible4(false)
                }}
                afterShow={() => {
                    searchRefPop.current?.focus()
                }}
                position='right'
                bodyStyle={{ width: '100vw' }}
            >
                <div className={classPrefix + '-header'}>
                    <div className={'left'}>
                        <NavBar
                            style={{
                                '--height': 'inherit',
                            }}
                            onBack={() => {
                                setVisible4(false)
                            }}
                        >
                            <SearchBar
                                ref={searchRefPop}
                                // defaultValue={urlParams.keyWord}
                                placeholder="搜索"
                                showCancelButton
                                style={{
                                    "--background": "#ffffff",
                                    padding: "15 12 15 12",
                                }}
                                onSearch={(val) => {
                                    Toast.show(`你搜索了：${val}`);
                                    //防抖 返回匹配的关键词列表
                                }}
                                onFocus={() => {
                                    Toast.show("获得焦点");
                                }}
                                onClear={() => {
                                    Toast.show("清空内容");
                                    // onSearch()
                                }}
                                onCancel={() => {
                                    Toast.show("取消搜索");
                                }}

                            />
                        </NavBar>
                    </div>
                </div>
            </Popup>
        </>
    )
}