import { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Freeze } from 'react-freeze';
import { UNSAFE_RouteContext as RouteContext, matchPath } from 'react-router-dom';
import cacheContext from './context'
export interface KeepAliveOutletProps {
    exclude?: string[]
    max?: number
}

function useUpdate(): () => void {
    const [, setTrigger] = useState({})
    const update = useCallback(() => setTrigger({}), [])
    return update
}

function KeepAliveOutlet({ exclude = [], max = Infinity }: KeepAliveOutletProps = {}) {
    const caches = useRef<Record<string, ReactElement>>({});

    const matchedElement = useContext(RouteContext).outlet; // v6.3之后useOutlet会多了一层嵌套
    const elementIndex = matchedElement?.props?.value?.matches?.length -1 || 0;
    const matchedPath = matchedElement?.props?.match?.pathname as string;
    const update = useUpdate()
    function useCacheOutletsControl() {
        return useContext(cacheContext)
    }
    const { drop: dropOutside } = useCacheOutletsControl()

    const drop = useCallback((path: string) => {
        delete caches.current[path]

        if (typeof dropOutside === 'function') {
            dropOutside(path)
        }

        update()
    }, [])

    if (matchedElement && matchedPath) {
        caches.current[matchedPath] = matchedElement;
    }
    // console.log(Object.entries(caches), matchedElement, matchedPath)

    useEffect(() => {
        const match = exclude.some((pattern) => matchPath(pattern, matchedPath) !== null);
        if (match) {
            return () => drop(matchedPath)
        }
    }, [matchedPath])
    return (
        <>
            {Object.entries(caches.current).map(([path, element]) => (
                <Freeze key={path} freeze={element !== matchedElement}>
                    {element}
                </Freeze>
            ))}
        </>
    );
}

export default KeepAliveOutlet;