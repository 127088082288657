import { Button, ErrorBlock } from "antd-mobile"
import React from 'react'
import { useNavigate } from "react-router-dom";
const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <ErrorBlock fullPage status="empty">
                <Button color='primary' onClick={()=> navigate(-1)}>Go Back</Button>
            </ErrorBlock>
        </>
    )
}

export default NotFoundPage