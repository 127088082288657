import { FC, Suspense, useEffect, useState } from 'react'
import {
    useLocation,
    useNavigate,
} from 'react-router-dom'
import {
    AppOutline,
} from 'antd-mobile-icons'
import { ShopOutlined, HomeOutlined, ShoppingCartOutlined, BarChartOutlined } from '@ant-design/icons'
import './style.less'
import { Layout } from 'antd'
import KeepAliveOutlet from './KeepAliveOutlet'
import { TabBar } from 'antd-mobile'

// import { checkLoginState } from '../../../components/login/authUtil'

const Bottom: FC = () => {

    const location = useLocation()
    const { pathname } = location
    const navigate = useNavigate();

    //   const setRouteActive = (value: string) => {
    //     history.push(value)
    //   }
    function onChangeTab(key: string) {
        navigate(key, { replace: false });
    }

    const tabs = [
        {
            key: '/mall',
            title: '首页',
            icon: <AppOutline />,
        },
        {
            key: '/mall/vlist',
            title: '采购',
            icon: <ShopOutlined />,
        },
        {
            key: '/mall/dailybuy',
            title: '常购',
            icon: <HomeOutlined />,
        },
        {
            key: '/mall/cart',
            title: '购物车',
            icon: <ShoppingCartOutlined />,
        },
        {
            key: '/mall/account',
            title: '我的',
            icon: <BarChartOutlined />,
        },
    ]

    return (
        <TabBar safeArea activeKey={pathname} onChange={value => onChangeTab(value)}>
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
    )
}
const { Footer } = Layout;
export default () => {
    const navigate = useNavigate();
    //移动端跳转
    const isMobile = () => {
        let flag = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

        return flag;
    }

    const [isMobileState, setIsMobileState] = useState(isMobile())

    useEffect(() => {
        console.log('mount mobile layout!')
        // if(!isMobileState){ //移动端
        //     navigate("127.0.0.1:3000", { replace: true });
        // }
        // const id = setInterval(() => {
        //     console.log('polling')
        //     // checkLoginState(navigate, location);
        // }, 60 * 1000);
        // return () => clearInterval(id);
    }, [])
    return (
        <>
            <div id='mall' className='mall'>
                <div id='mall-content' className='mall-content' >
                    <Suspense fallback={<div>Loading...</div>} >
                        <KeepAliveOutlet exclude={['/mall/', '/mall/search', '/mall/cart', '/login', '/mall/account', '/mall/order', '/mall/order/:id']} />
                    </Suspense>
                </div>
                <Footer className='mall-footer'>
                    <Bottom />
                </Footer>
            </div>
        </>
    )
}
