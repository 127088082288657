
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';
import { ActionSheet, ErrorBlock, Image, NavBar } from 'antd-mobile';
import { logo } from '../../../assets';
import { temploginurl } from './request';
import './style.less';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Button, Typography } from 'antd';
import { Action } from 'antd-mobile/es/components/action-sheet';
import { useNavigate } from 'react-router-dom';
dayjs.extend(relativeTime);
const classPrefix = 'mall-qrcode';
const { Paragraph } = Typography;
export default () => {
    const [data, setData] = useState({ url: '', expired_time: '' });
    const [visible, setVisible] = useState(false);
    const [minutes, SetMinutes] = useState<number>();
    const navigate = useNavigate()
    const actions: Action[] = [
        { text: '30分钟', key: '30', onClick: () => SetMinutes(30) },
        { text: '1小时', key: '60', onClick: () => SetMinutes(60) },
        { text: '3小时', key: '180', onClick: () => SetMinutes(180) },
    ]
    useEffect(() => {
        temploginurl().then((res: any) => {
            setData(res);
        });
        const polling = setInterval(() => {
            temploginurl().then((res: any) => {
                setData(res);
            });
        }, 60 * 1000);
        return () => clearInterval(polling);
    }, []);

    useEffect(() => {
        if (minutes) {
            temploginurl(minutes, true).then((res: any) => {
                setData(res);
            });
        }
    }, [minutes]);
    return (
        <>
            <NavBar
                right={<Button onClick={() => setVisible(true)}>有效期</Button>}
                onBack={() => navigate(-1)}
                style={{
                    '--border-bottom': '1px #eee solid',
                }} >
                二维码
            </NavBar>
            <div className={classPrefix + ' ' + classPrefix + '-full-page'}>
                {data.url !== '' ? (
                    <>
                        <div className={classPrefix + '-image'}>
                            <QRCodeSVG
                                width={200}
                                height={200}
                                value={data.url}
                                level={"H"}
                                imageSettings={{
                                    src: logo,
                                    x: undefined,
                                    y: undefined,
                                    height: 50,
                                    width: 50,
                                    excavate: true,
                                }}
                            />
                        </div>
                        <div className={classPrefix + '-description'}>
                            <div className={classPrefix + '-description-title'}>
                                扫码登录
                            </div>
                            <div className={classPrefix + '-description-subtitle'}>
                                有效期至: {dayjs(data.expired_time).format('YYYY-MM-DD HH:mm:ss')}
                            </div>
                            <div className={classPrefix + '-description-content'}>
                                扫码登录后，可以使用网页APP进行购买
                            </div>
                            <Paragraph copyable={{ text: data.url }}>登陆链接</Paragraph>
                        </div>
                    </>
                ) : <div></div>}
            </div>
            <ActionSheet
                visible={visible}
                actions={actions}
                closeOnAction
                onClose={() => setVisible(false)}
            />
        </>
    )
}