import React from 'react'
import {
  Tag,
  List, Space, Image, InfiniteScroll
} from 'antd-mobile'
import { GetOrders } from './request'
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import './style.less'

const classPrefix = `mall-order-list`

const progressTag = (status: string) => {
  if (status) {
    const valueEnum: any = {
      draft: { text: '草稿', status: <Tag color='danger'>草稿</Tag> },
      pending: { text: '待审核', status: <Tag color='warning'>待审核</Tag> },
      open: { text: '处理中', status: <Tag color='primary'>处理中</Tag> },
      archived: { text: '已完成', status: <Tag color='success'>已完成</Tag> },
      canceled: { text: '已取消', status: <Tag color='default'>已取消</Tag> },
    }
    return (
      valueEnum[status].status
    )
  } else {
    return <Tag color='danger'>无</Tag>
  }
}


type params = {
  pageSize: number
  current?: number
  orderstype?: string
  ordersstatus?: string
  bizname?: string
  startDate?: string
  endDate?: string
}


const MobileOrderList = ({ status }: { status: string }) => {
  const navigate = useNavigate();
  const [list, setList] = React.useState<any[]>([])
  const [activeStatus, setActiveStatus] = React.useState<string>(status)
  const [morePages, setMorePages] = React.useState(true);
  const [param, setParam] = React.useState<params>({
    pageSize: 10,
    current: 0
  })
  const [pageNumber, setPageNumber] = React.useState(1)
  async function loadMore() {
    // let append: { data: any; total: number; page: number; success: boolean };
    // console.log(param)
    const append = await GetOrders({ ...param, current: pageNumber, status: activeStatus })
    setPageNumber(pageNumber + 1)

    if (append?.data) {
      setList((val: any) => [...val, ...append?.data])
    } else {
      setMorePages(false)
    }
    setMorePages(append?.data.length > 0)
  }

  const onClick = (item: any) => {
    navigate(`${item.id}`)
  }

  return (
    <>
      <div className={classPrefix + '-container'}>
        <List >
          {list?.map((i: any, index) => (
            <List.Item
              key={index}
              extra={"¥" + i.total}
              title={<Space>{i?.ordernumber} {progressTag(i?.status)}</Space>}
              description={dayjs(i.created_time).format('YYYY-MM-DD HH:mm:ss')}
              clickable
              onClick={() => { onClick(i) }}
            >
              <Space>
                {i.products?.map((_sku: any, index: number) => {
                  if (index < 5) {
                    return (
                      <Image
                        key={index}
                        src={''}
                        // style={{ borderRadius: 20 }}
                        fit='cover'
                        width={40}
                        height={40}
                      />
                    )
                  }else{
                    return null
                  }
                })}
              </Space>
            </List.Item>
          ))}
        </List>
        <InfiniteScroll threshold={250} loadMore={loadMore} hasMore={morePages} />
      </div>
    </>
  )
};

export default MobileOrderList;