import {
  Image,
  Dropdown,
  SideBar,
  Space,
  Grid,
  Tabs,
  Toast,
  Button,
  SearchBar,
  DotLoading,
  ImageViewer,
} from "antd-mobile";
import {
  Key,
  ReactChild,
  ReactFragment,
  ReactPortal,
  useEffect,
  useRef,
  useState,
} from "react";
import "./style.less";
import { getCategoryList, ProductList } from "./request";
// import { GetCate } from "../../../components/pages/productInfo/addproduct/request";
// import { localizations } from "../../language/cn_zh";
import AddToCart from "./add_to_cart";
import { IndexBarRef } from "antd-mobile/es/components/index-bar";

import Collapse from "./collapse";
import IndexBar from "../../components/index-bar";
import { DropdownRef } from "antd-mobile/es/components/dropdown";
import useUrlState from '@ahooksjs/use-url-state';
import { useNavigate } from "react-router-dom";
import { useRequest } from "ahooks";

const classPrefix = `goods-list`;
const GoodsList = (props: any) => {
  const [state, setState] = useUrlState();
  const [sideBarKey, setSideBarKey] = useState("0");
  const [catelogIndex, setCatelogIndex] = useState("0");
  const [params, setParams] = useState<{
    current: number;
    pageSize: number;
    sku: boolean;
    product_sku_name?: string;
  }>({
    current: 1,
    pageSize: 50,
    sku: true,
  });
  // const [data, setData] = useState(groups);
  const [catelogs, setCatelogs] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const indexBarRef = useRef<IndexBarRef>(null)
  const dropdownRef = useRef<DropdownRef>(null)
  const navigate = useNavigate();

  async function getData(category_id?: string) {
    console.log('getData', state.id, catelogs?.[0]?.children?.[0]?.value, category_id)
    if (state.id) { //url parameters
      const append = await ProductList({ ...params, current: pageNumber },
        state.id);
        return append;
    } else if (category_id) { //first catelog
      const append = await ProductList({ ...params, current: pageNumber },
        category_id);
        return append;
    }
  }

  const withLoadingDelayAction = useRequest(
    getData, {
    loadingDelay: 300,
  });

  async function loadMore(category_id: any) {
    if (pageNumber > 1) {
      setPageNumber(pageNumber + 1);
      console.log("loadmore", category_id);
      // setLoading(true);
      // const append = await ProductList({ ...params, current: pageNumber },
      //   category_id);
      withLoadingDelayAction.run(category_id)
      // setData(append.data);
      // setLoading(false);

    } else {
      setPageNumber(pageNumber + 1);
      console.log("load first page", category_id);
      // setLoading(true);
      // const append = await ProductList({ ...params, current: pageNumber },
      //   category_id);
      withLoadingDelayAction.run(category_id)
      // setData(append.data);
      // setLoading(false);
    }
  }

  function onCatelogChange(key: string) {
    console.log("catelog: " + key);
    setCatelogIndex(key); //set catelog index
    setSideBarKey("0"); //reset sideBarKey
    setState({ id: catelogs?.[Number(key)]?.children?.[0]?.value });
  }

  function onSidebarChange(key: string) {
    console.log("sidebar: " + key);
    setSideBarKey(key); //set sideBarKey
    setState({ id: catelogs?.[Number(catelogIndex)]?.children?.[Number(key)]?.value });
  }

  function findCatelogIndex(id: string, data: any): any {
    console.log("findCatelogIndex: " + id);
    data.forEach((item: { value: Number; children: { value: Number; }[]; }, i: any) => {
      if (item.value === Number(id)) {
        setCatelogIndex(i.toString());
        setSideBarKey("0");
      } else {
        if (item.children) {
          item.children.forEach((child: { value: Number; }, index: any) => {
            // console.log(child.value === Number(id))
            if (child.value === Number(id)) {
              setCatelogIndex(i.toString());
              setSideBarKey(index.toString());
            }
          })
        }
      }
    })
  };

  useEffect(() => {
    getCategoryList().then((res) => {
      setCatelogs(res.data);
      if (state.id) {
        findCatelogIndex(state.id, res.data)
      } else {
        loadMore(res.data?.[0]?.children?.[0]?.value);
      }
    });

  }, [params]);

  useEffect(() => {
    setPageNumber(1);
    if (state.id) {
      if (catelogs?.[Number(catelogIndex)]?.children?.[Number(sideBarKey)]?.value !== Number(state.id)) {
        findCatelogIndex(state.id, catelogs);
      }
      loadMore(state.id);
    }
  }, [state.id]);
  
  return (
    <>
      <div className={classPrefix + '-search-bar-container'}>
        <SearchBar
          placeholder="请输入商品名"
          // showCancelButton

          style={{
            // "--background": "#ffffff",
            padding: "15 12 15 12",
          }}
          onSearch={(val) => {
            Toast.show(`你搜索了：${val}`);
            navigate(`/mall/search?keyWord=${val}`);
          }}
          onFocus={() => {
            Toast.show("获得焦点");
          }}
          onBlur={() => {
            Toast.show("失去焦点");
          }}
          onClear={() => {
            Toast.show("清空内容");
          }}
          onCancel={() => {
            Toast.show("取消搜索");
          }}
        />
      </div>
      <div className="catelog-nav-container">
        <Grid columns={8} gap={0} className="catelog-nav-bar">
          <Grid.Item span={7}>
            <Tabs
              defaultActiveKey="0"
              activeKey={catelogIndex}
              onChange={onCatelogChange}
            >
              {catelogs?.map((item, index) => (
                <Tabs.Tab className="catelog-tab" key={index} title={item.label}></Tabs.Tab>
              ))}
            </Tabs>
          </Grid.Item>
          <Grid.Item span={1}>
            <Dropdown
              className={classPrefix + "-dropdown"}
              style={{ height: "100%" }}
              ref={dropdownRef}
            >
              <Dropdown.Item key="sorter" title="" style={{ height: "100%" }}>
                <div style={{ padding: "12px", textAlign: "center" }}>
                  <Grid columns={4} gap={12}>
                    {catelogs.map((item, index) => (
                      <Grid.Item span={1} key={index}>
                        <Button onClick={() => {
                          setCatelogIndex(String(index))
                          setSideBarKey("0")
                          dropdownRef?.current?.close()
                        }} fill='none' size='mini'>
                          {item.label}
                        </Button>
                      </Grid.Item>
                    ))}
                  </Grid>
                </div>
              </Dropdown.Item>
            </Dropdown>
          </Grid.Item>
        </Grid>
      </div>
      <div className="goods-list-sidebar-container">
        <SideBar
          defaultActiveKey="0"
          activeKey={sideBarKey}
          onChange={onSidebarChange}
        >
          {catelogs?.[Number(catelogIndex)]?.children ? catelogs?.[Number(catelogIndex)]?.children.map(
            (
              item: {
                value: Key | null | undefined;
                label: string | undefined;
                badge:
                | boolean
                | ReactChild
                | ReactFragment
                | ReactPortal
                | null
                | undefined;
              },
              index: Key | null | undefined
            ) => (
              <SideBar.Item
                key={index}
                title={<div>
                  {item.label}
                </div>}
                badge={item.badge} />
            )
          ) : null}
        </SideBar>
      </div>
      <div className="goods-list-container">
        {loading ?
          <>
            <span>加载数据中
            </span>
            <DotLoading color='currentColor' />
          </> :
          <IndexBar ref={indexBarRef}>
            {withLoadingDelayAction?.data?.data?.map((group: { title: any; items: any; }) => {
              const { title, items } = group
              return (
                <IndexBar.Panel
                  index={title}
                  title={title}
                  key={title}
                >

                  <Collapse
                    style={{ '--padding-left': '0px', '--padding-right': '0px' }}
                  >
                    {items?.map((item: { image: string; images:string[];
                    product_spu_name: any; 
                    spu_spec: any; 
                    saleprice: any; children: any[]; }, index: any) => (
                      <Collapse.Panel
                        className={classPrefix + "-list-item"}
                        key={String(index)}
                        arrow={false}
                        // clickable={false}
                        prefix={
                          <Image
                            src={item?.images?.[0] || item.image}
                            //   style={{ borderRadius: 20 }}
                            onClick={() => {
                              ImageViewer.Multi.show({ images: item?.images })
                            }}
                            fit="cover"
                            width={100}
                            height={100}
                          />
                        }
                        title={
                          <>
                            <div className="w">
                              <h4 className={classPrefix + "-product-spu-name"}>
                                {item?.product_spu_name ? item?.product_spu_name : " "}
                              </h4>
                              <p className={classPrefix + "-spu-spec"}>
                                {item?.spu_spec ? item?.spu_spec : "规格"}
                              </p>
                              <p className={classPrefix + "-price"}>
                                {item?.saleprice ? item?.saleprice : "价格"}
                              </p>
                            </div>
                          </>
                        }
                      >
                        <Space direction='vertical' block>
                          {item?.children.map((child: any, index: any) => {
                            return (
                              <Grid key={'sku_name' + index} columns={12} gap={8}>
                                <Grid.Item span={4}>
                                  <p>{child?.product_sku_name ? child?.product_sku_name : " "}</p>
                                </Grid.Item>
                                <Grid.Item key={'sku_spec' + index} span={3}>
                                  <div className={classPrefix + '-product-sku-spec'}>{child?.sku_spec}</div>
                                </Grid.Item>
                                <Grid.Item key={'unit' + index} span={3}>
                                  <div className={classPrefix + '-product-sku-unit'}>{child?.saleprice}/{child?.unit}</div>
                                </Grid.Item>
                                <Grid.Item key={'cart' + index} span={2}>
                                  <div >
                                    <AddToCart
                                      product={child}
                                      value={props.value}
                                      onChange={props.onChange}
                                    /></div>
                                </Grid.Item>
                              </Grid>
                            )
                          })
                          }
                        </Space>
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                </IndexBar.Panel>
              )
            })}
          </IndexBar>
        }
        {/* <Button onClick={loadMore}>加载更多</Button> */}
      </div>
    </>
  );
};

export default GoodsList;
