import { Input, Picker, Space } from "antd-mobile"
import { InputProps, InputRef } from "antd-mobile/es/components/input";
import { DownOutline } from 'antd-mobile-icons'
import { FC, forwardRef, useState } from "react"
import { PickerValue } from "antd-mobile/es/components/picker";
const classPrefix = "mall-login"
export interface MobileValue {
    code: string | number
    phone: string
}
const columns = [[{
    label: '中国',
    value: '86',
    key: 'CN',
}, {
    label: '中国香港',
    value: '852',
    key: 'HK',
}, {
    label: '中国澳门',
    value: '853',
    key: 'MO',
}, {

    label: '中国台湾',
    value: '886',
    key: 'TW',
}]]
interface PhoneInputProps {
    value?: MobileValue
    onChange?: (value: MobileValue) => void
    fieldProps?: InputProps
    placeholder?: string
}

const PhoneInput: FC<PhoneInputProps> = ({
    value = { code: '86', phone: '' },
    onChange,
    fieldProps,
    placeholder
}) => {
    const [visible, setVisible] = useState(false)

    const triggerValue = (changedValue: Partial<MobileValue>) => {
        onChange?.({ ...value, ...changedValue })
    }

    const onRealValueChange = (value: string) => {
        triggerValue({ phone: value })
    }

    const onPreValueChange = (value: PickerValue[]) => {
        const v = value[0]
        if (v === null) return
        triggerValue({ code: v })
    }
    return (
        <>
            <Space align='center'>
                <Space align='center' onClick={() => setVisible(!visible)} >
                    <div>+{value.code}</div>
                    <DownOutline />
                </Space>
                <Input
                    {...fieldProps}
                    placeholder={placeholder}
                    value={value.phone}
                    onChange={onRealValueChange}
                />
            </Space>
            <Picker
                columns={columns}
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
                value={[value.code]}
                onConfirm={onPreValueChange}
            />
        </>
    )
}
export default PhoneInput;