import { Card, Divider, Grid, Image, Swiper, Toast } from "antd-mobile";
import "./style.less";
import { logo, broccoli, fish, seasoning, dairy, liquor, poultry, meat, rice, snack, spatula } from "../../../assets";
import { useNavigate } from "react-router-dom";
import SearchPage from "../03-List/search";
import { useEffect } from "react";
import { preventScrollBG } from "../../utils/preventScrollBG";

const colors = ['#ace0ff', '#bcffbd', '#e4fabd', '#ffcfac']
const classPrefix = 'mall-home'
const items = colors.map((color, index) => (
    <Swiper.Item key={index}>
        <div
            className={'content'}
            // style={{ background: color }}
            onClick={() => {
                Toast.show(`你点击了卡片 ${index + 1}`)
            }}
        >
            <Image width={128}
                height={128}
                src={logo} />
        </div>
    </Swiper.Item>
))
const demoSrc =
    'https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=60'
const categories = [
    {
        category_id: 41,
        category_name: "调料干货",
        category_img: seasoning,
        parent_id: null,
        categorydetail: null,
        created_time: "2022-03-18T08:02:05.205+00:00",
        updated_time: "2022-03-20T07:46:24.362+00:00"
    },
    {
        category_id: 111,
        category_name: "蔬果生鲜",
        category_img: broccoli,
        parent_id: null,
        categorydetail: null,
        created_time: "2022-03-18T08:02:05.205+00:00",
        updated_time: "2022-03-20T07:46:24.362+00:00"
    },
    {
        category_id: 109,
        category_name: "水产冻货",
        category_img: fish,
        parent_id: null,
        categorydetail: null,
        created_time: "2022-03-18T08:02:05.205+00:00",
        updated_time: "2022-03-20T07:46:24.362+00:00"
    },
    {
        category_id: 110,
        category_name: "猪牛羊肉",
        category_img: meat,
        parent_id: null,
        categorydetail: null,
        created_time: "2022-03-18T08:02:05.205+00:00",
        updated_time: "2022-03-20T07:46:24.362+00:00"
    },
    {
        category_id: 121,
        category_name: "鸡鸭禽蛋",
        category_img: poultry,
        parent_id: null,
        categorydetail: null,
        created_time: "2022-03-18T08:02:05.205+00:00",
        updated_time: "2022-03-20T07:46:24.362+00:00"
    },
    {
        category_id: 44,
        category_name: "米面粮油",
        category_img: rice,
        parent_id: null,
        categorydetail: null,
        created_time: "2022-03-18T08:02:05.205+00:00",
        updated_time: "2022-03-20T07:46:24.362+00:00"
    },
    {
        category_id: 65,
        category_name: "乳品烘培",
        category_img: dairy,
        parent_id: null,
        categorydetail: null,
        created_time: "2022-03-18T08:02:05.205+00:00",
        updated_time: "2022-03-20T07:46:24.362+00:00"
    },
    {
        category_id: 39,
        category_name: "休闲食品",
        category_img: snack,
        parent_id: null,
        categorydetail: null,
        created_time: "2022-03-18T08:02:05.205+00:00",
        updated_time: "2022-03-20T07:46:24.362+00:00"
    },
    {
        category_id: 122,
        category_name: "酒水饮料",
        category_img: liquor,
        parent_id: null,
        categorydetail: null,
        created_time: "2022-03-18T08:02:05.205+00:00",
        updated_time: "2022-03-20T07:46:24.362+00:00"
    },
    {
        category_id: 108,
        category_name: "餐厨用具",
        category_img: spatula,
        parent_id: null,
        categorydetail: null,
        created_time: "2022-03-18T08:02:05.205+00:00",
        updated_time: "2022-03-20T07:46:24.362+00:00"
    },
]

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const actions = preventScrollBG(classPrefix + '-container');
        return () => {
            actions.removeListener();
        };
    }, [])
    return (
        <>
            <div className={classPrefix + '-search-bar-container'}>
                <SearchPage />
            </div>
            <div id={classPrefix + '-container'} className={classPrefix + '-container'} >
                <Swiper
                >{items}
                </Swiper>
                <div className={classPrefix + '-cate'} >
                    <Divider contentPosition='left'>商品类目</Divider>
                    <Grid columns={4} gap={0}>
                        {categories.map(category => {
                            return (
                                <Grid.Item key={category.category_name}>
                                    <Card
                                        style={
                                            {
                                                display: 'flex',
                                                justifyContent: "center",
                                                borderRadius: '0px',
                                                // boxShadow:'0 0 0 1px #d9d9d9'
                                                // border: '1px solid #d9d9d9',
                                            }
                                        }
                                        bodyStyle={{
                                            textAlign: 'center'
                                        }}
                                        onClick={() => {
                                            navigate(`/mall/vlist?id=${category.category_id}`, { replace: false });
                                        }}
                                    >
                                        <Image
                                            src={category.category_img}
                                            width={64}
                                            height={64}
                                            fit='cover'
                                            style={{ borderRadius: 8 }}
                                        />
                                        <h4>{category.category_name}</h4>
                                    </Card>
                                </Grid.Item>)
                        })}
                    </Grid>
                </div>
                {/* <div className={classPrefix + '-brand'} >
                    <Divider contentPosition='left'>品牌专区</Divider>
                </div> */}
            </div>
        </>
    )
}
export default Home;