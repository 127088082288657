import request from "umi-request";
import { logout } from "../08-Login/request";

export const postOrder = (data: any) =>
  request(`/api/v2/mallorder`, {
    method: "post",
    data: data,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwtToken"),
    },
  })
    .then(function (response: any) {
      return response;
    })
    .catch(function (error: any) {
      if (error?.response?.status === 401) {
        logout();
      }
      return error;
    });
