import React from 'react';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
//Login
// import Login from './components/login';

// system
import Layout from './mall/pages/01-Layout';
import Home from './mall/pages/02-Home';
import MallList from './mall/pages/03-List';
import MallSearch from './mall/pages/03-List/sku_list';
import MallOrder from './mall/pages/04-Order';
import OrderForm from './mall/pages/04-Order/detail_list'
import MallCart from './mall/pages/05-Cart';
import MallUser from './mall/pages/06-User';
import Login, { Signup } from './mall/pages/08-Login';
import MallUserProfile from './mall/pages/09-UserProfile';
// const MobileDevPage = lazy(() => import('./mobile_components/pages/dev_page'));

import RequireAuth from './mall/pages/08-Login/requireAuth';
import UnderDevPage from './mall/pages/07-Dev';
import TrendingItems from './mall/pages/10-Trending';
import SearchPage from './mall/pages/03-List/search';
import QRCode, { QRLoginPage } from './mall/pages/11-QRCode';
import VirtualizedList from './mall/pages/03-List/virtualizedList';
import NotFoundPage from './mall/pages/07-Dev/notFound';

const routes = [
    {
        path: '/',
        element: <Navigate to="/mall" />
    },
    {
        path: 'login',
        element: <Login />,
        children: [
            { path: '*', element: <Navigate to="/404" /> }
        ]
    },
    {
        path: 'register',
        element: <Signup />,
        children: [
            { path: '*', element: <Navigate to="/404" /> }
        ]
    },
    {
        path: 'testing',
        element: <SearchPage />
    },
    {
        path: 'qrcode',
        element: <RequireAuth><QRCode /></RequireAuth>
    },
    {
        path: 'qrcodelogin/:id',
        element: <QRLoginPage />
    },
    {
        path: 'underdev',
        element: <UnderDevPage />
    },
    { path: '*', element: <NotFoundPage /> },
    {
        path: '/mall',
        element: <Layout />,
        children: [
            { index: true, element: <Home /> },
            {
                path: 'list',
                element:
                    <RequireAuth>
                        <MallList />
                    </RequireAuth>,
            },
            {
                path: 'search',
                element:
                    <RequireAuth><MallSearch /></RequireAuth>
            },
            {
                path: 'order',
                element:<RequireAuth><MallOrder /></RequireAuth>

            },
            { path: 'order/:id', element: <RequireAuth><OrderForm /></RequireAuth> },
            { path: 'cart', element: <RequireAuth><MallCart /></RequireAuth> },
            { path: 'account', element: <MallUser /> },
            { path: 'profile', element: <MallUserProfile /> },
            { path: 'dailybuy', element: <RequireAuth><TrendingItems /></RequireAuth> },
            {
                path: 'vlist',
                element: <RequireAuth><VirtualizedList /></RequireAuth>
            },
            { path: '*', element: <UnderDevPage /> }
        ]
    }
];

export default routes;