import { Button, Space, Toast } from "antd-mobile"
// import Stepper from "../stepper"
import { Big } from "big.js"

export type product =
    {
        defwhs?: boolean
        product_sku_id?: number
        product_sku_name?: string
        sku_spec?: string
        unit: string
        price: string | number
        saleprice?: string | number;
        lastsaleprice?: string | number;
        quantity: number
        amount?: string
        notes?: string
    }
// {
//     "defwhs": true,
//     "product_sku_id": 1915,
//     "product_sku_name": "红酒",
//     "sku_spec": "",
//     "unit": "桶",
//     "price": "20.000",
//     "cost": "100.000",
//     "quantity": 1,
//     "amount": "20"
// },
interface PriceInputProps {
    product: any;
    value: product[];
    onChange?: (value: product[]) => void;
}

const AddToCart: React.FC<PriceInputProps> = ({ product, value }) => {
    const triggerChange = (changedValue: product) => {
        // sessionStorage.setItem('local-cart', '[]') 
        let formData = JSON.parse(sessionStorage.getItem('local-cart') || '[]');
        if (formData) {
            changedValue.amount = Big(changedValue.price).times(changedValue.quantity).toFixed(2);
            // setFormData(formData.map((item: any) => item.product_sku_id === changedValue.product_sku_id ? changedValue : item));
            //处理数据
            const currentproduct = formData.find((item: any) => item.product_sku_id === changedValue.product_sku_id);
            if (currentproduct) {
                currentproduct.quantity = currentproduct.quantity + changedValue.quantity;
                currentproduct.amount = Big(currentproduct.price).times(currentproduct.quantity).toFixed(2);
                sessionStorage.setItem('local-cart',JSON.stringify(formData));
                Toast.show({
                    icon: 'success',
                    content: currentproduct.quantity + currentproduct.unit,
                    duration: 500,
                })
            } else {
                sessionStorage.setItem('local-cart',JSON.stringify([...formData, changedValue]));
                Toast.show({
                    icon: 'success',
                    content: changedValue.quantity + changedValue?.unit,
                    duration: 500,
                })
            }
        } else {
            sessionStorage.setItem('local-cart',JSON.stringify([changedValue]));
            Toast.show({
                icon: 'success',
                content: changedValue.quantity + changedValue?.unit,
                duration: 500,
            })
        }

    };
    // const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newNumber = parseInt(e.target.value || '0', 10);
    //     if (Number.isNaN(number)) {
    //         return;
    //     }
    //     if (!('number' in value)) {
    //         setNumber(newNumber);
    //     }
    //     triggerChange({ number: newNumber });
    // };

    const onAddToCart = (newProduct: product) => {
        triggerChange(newProduct);
    };
    return (
        <>
            <Space >
                {/* <Stepper
                    // defaultValue={1}
                    value={quantity}
                    onFocus={() => {
                        Toast.show('获得焦点')
                        // openKeyboard('demo4')
                    }}
                    min={0}
                    onChange={setQuantity}
                    digits={2}
                /> */}
                <Button size="mini"
                    color='primary'
                    onClick={() => {
                        console.log('add to cart');
                        onAddToCart({
                            defwhs: true,
                            product_sku_id: product.product_sku_id,
                            product_sku_name: product.product_sku_name,
                            sku_spec: product.sku_spec ? product.sku_spec : '',
                            unit: product.unit,
                            price: product.saleprice ? product.saleprice : "1",
                            amount: Big(product.saleprice ? product.saleprice : 1).times(1).toFixed(2),
                            quantity: 1,
                        });
                    }}
                >
                    +
                </Button>
            </Space>
        </>
    )
}


export default AddToCart;