import './App.less';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
// import { readCookie } from './components/login/authUtil';
import 'antd/dist/reset.css';

function App() {
  const routing = useRoutes(routes);
  return (
    <>
      {routing}
    </>
  );
}

export default App;
