import {
  Image,
  Dropdown,
  SideBar,
  Space,
  Grid,
  Tabs,
  Toast,
  Button,
  SearchBar,
  DotLoading,
  List as ListView,
  ImageViewer,
  Popup,
  Divider,
} from "antd-mobile";
import {
  Key,
  LegacyRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import "./style.less";
import { getCategoryList, ProductList } from "./request";
// import { GetCate } from "../../../components/pages/productInfo/addproduct/request";
import AddToCart from "./add_to_cart";

import { DropdownRef } from "antd-mobile/es/components/dropdown";
import useUrlState from '@ahooksjs/use-url-state';
import { useNavigate } from "react-router-dom";
// import { FixedSizeList } from 'react-window';
// import AutoSizer from 'react-virtualized-auto-sizer';
// @ts-ignore
import { List, AutoSizer } from "react-virtualized";
import { Sidebar } from "../../components/index-bar/sidebar";

const charCodeOfA = 'A'.charCodeAt(0)
const groups = Array(1) //fake data
  .fill('')
  .map((_, i) => ({
    title: String.fromCharCode(charCodeOfA + i) + '种类',
    items:
      [{
        product_spu_id: 3,
        image:
          'https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png',
        product_spu_name: String.fromCharCode(charCodeOfA + i) + '海天金标生抽',
        spu_spec: '1*12',
        tags: ['吃货', '游泳'],
        saleprice: '1500',
        lastsaleprice: '1500',
        product_sku_desc: 'Ab animi cumque eveniet ex harum nam odio omnis',
        children: [
          {
            product_sku_id: 1,
            image:
              "//img10.360buyimg.com/n7/s525x525_jfs/t1/135536/18/15271/59952/5fa8fd93Eddc8dd80/194c14ceef4f1e7e.jpg!q70.dpg.webp",
            product_sku_name: '名字超长的印度辣椒干（包）500g散装++++aaaaa',
            tags: ['吃货', '游泳'],
            saleprice: '$1,500',
            sku_spec: '散装',
            unit: '斤'
          },
          {
            product_sku_id: 2,
            image:
              "//img10.360buyimg.com/n7/s525x525_jfs/t1/135536/18/15271/59952/5fa8fd93Eddc8dd80/194c14ceef4f1e7e.jpg!q70.dpg.webp",
            product_sku_name: '印度辣椒干',
            tags: ['吃货', '游泳'],
            saleprice: '$1,500',
            sku_spec: '散装',
            unit: '斤'
          },
        ]
      }]
  }))

const classPrefix = `vlist`;
const GoodsList = (props: any) => {
  const [state, setState] = useUrlState();
  const [sideBarKey, setSideBarKey] = useState("0");
  const [catelogIndex, setCatelogIndex] = useState("0");
  const [params, setParams] = useState<{
    current: number;
    pageSize: number;
    sku: boolean;
    product_sku_name?: string;
  }>({
    current: 1,
    pageSize: 50,
    sku: true,
  });
  const [data, setData] = useState(groups);
  const [catelogs, setCatelogs] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [visible, setVisible] = useState(false);
  const [popupItem, setPopupItem] = useState<any>({})
  const dropdownRef = useRef<DropdownRef>(null)
  const navigate = useNavigate();

  async function loadMore(category_id: any) {
    if (pageNumber > 1) {
      setPageNumber(pageNumber + 1);
      console.log("loadmore");
      setLoading(true);
      const append = await ProductList({ ...params, current: pageNumber },
        category_id);
      setData(append.data);
      setLoading(false);

    } else {
      setPageNumber(pageNumber + 1);
      console.log("load first page");
      setLoading(true);
      const append = await ProductList({ ...params, current: pageNumber },
        category_id);
      setData(append.data);
      setLoading(false);
    }
  }

  function onCatelogChange(key: string) {
    console.log("catelog: " + key);
    setCatelogIndex(key); //set catelog index
    setSideBarKey("0"); //reset sideBarKey
    setState({ id: catelogs?.[Number(key)]?.children?.[0]?.value });
  }

  function onSidebarChange(key: string) {
    console.log("sidebar: " + key);
    setSideBarKey(key); //set sideBarKey
    setState({ id: catelogs?.[Number(catelogIndex)]?.children?.[Number(key)]?.value });
  }

  function findCatelogIndex(id: string, data: any): any {
    console.log("findCatelogIndex: " + id);
    data.forEach((item: { value: Number; children: { value: Number; }[]; }, i: any) => {
      if (item.value === Number(id)) {
        setCatelogIndex(i.toString());
        setSideBarKey("0");
      } else {
        if (item.children) {
          item.children.forEach((child: { value: Number; }, index: any) => {
            // console.log(child.value === Number(id))
            if (child.value === Number(id)) {
              setCatelogIndex(i.toString());
              setSideBarKey(index.toString());
            }
          })
        }
      }
    })
  };

  const listRef = useRef<List>({} as List)
  const [activeIndex, setActiveIndex] = useState(() => {
    const firstItem = data[0]
    return firstItem ? firstItem.title : null
  })
  const renderIndexTabs = () => {
    const indexItems: {
      index: string
      brief: ReactNode
    }[] = []
    data?.forEach((item, index) => {
      indexItems.push({
        index: String(index),
        brief: item.title,
      })
    })
    return <Sidebar
      indexItems={indexItems}
      activeIndex={activeIndex}
      onActive={index => {
        listRef.current.scrollToRow(Number(index))
      }}
    />
  }

  const rowRenderer = ({ key, index, style }: any) => {
    const { title, items } = data[index]
    // console.log(title, items.length)
    return (
      <div key={key} style={style} >
        <div className={classPrefix + "-anchor-title"}>{title}</div>
        <ListView
          style={{ '--padding-left': '0px', '--padding-right': '0px' }}
        >
          {items?.map((item: any, index: any) => (
            <ListView.Item
              className={`${classPrefix}-list-item`}
              key={String(index)}
              prefix={<Image
                src={item?.images?.[0]}
                //   style={{ borderRadius: 20 }}
                onClick={() => {
                  ImageViewer.Multi.show({ images: item?.images })
                }}
                fit="contain"
                lazy={true}
                width={100}
                height={100}
              />}
            >
              <Grid columns={12} gap={1}>
                <Grid.Item span={12}>
                  <div className="w">
                    <h4 className={classPrefix + "-product-spu-name"}>
                      {item?.product_spu_name ? item?.product_spu_name : " "}
                    </h4>
                    <p className={classPrefix + "-spu-spec"}>
                      {item?.spu_spec ? item?.spu_spec : "规格"}
                    </p>
                    <div className={classPrefix + "-price"}>
                      {item?.children?.map((i: any, index: string) => {
                        return (
                          <div key={'pricediv'+index} style={{margin:'0px 10px'}}>
                            {i?.saleprice ? `¥${i?.saleprice}` : "暂无"}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Grid.Item>
                <Grid.Item span={12}>
                  <Button
                    size='mini'
                    block
                    color='success'
                    // onClick={handleClick}
                    onClick={() => {
                      setPopupItem(item)
                      setVisible(true);
                    }
                    }
                  >
                    选择规格
                  </Button>
                </Grid.Item>
              </Grid>
            </ListView.Item>
          ))}
        </ListView>
      </div>
    )
  };
  const onRowsRendered = ({ startIndex }: any) => {
    // console.log(startIndex, activeIndex)
    if (activeIndex !== startIndex) {
      setActiveIndex(String(startIndex))
    }
  }

  const getRowHeight = ({ index }: { index: number }): number => {
    const letter = data[index].title;
    const length = data[index].items?.length; 
    console.log('index', index, letter, length ? length * 111 + 35 : 10 * 111 + 35);
    return length ? length * 111 + 35 : 10 * 111 + 35
  }

  useEffect(() => {
    getCategoryList().then((res) => {
      setCatelogs(res.data);
      if (state.id) {
        findCatelogIndex(state.id, res.data)
      } else {
        loadMore(res.data?.[0]?.children?.[0]?.value);
      }
    });

  }, [params]);

  useEffect(() => {
    setPageNumber(1);
    if (state.id) {
      if (catelogs?.[Number(catelogIndex)]?.children?.[Number(sideBarKey)]?.value !== Number(state.id)) {
        findCatelogIndex(state.id, catelogs);
      }
      loadMore(state.id);
    }
  }, [state.id]);

  // useEffect(() => {
  //   reduceMotion();
  // }, [])
  return (
    <>
      <div className={classPrefix + '-search-bar-container'}>
        <SearchBar
          placeholder="请输入商品名"
          style={{
            padding: "15 12 15 12",
          }}
          onSearch={(val) => {
            Toast.show(`你搜索了：${val}`);
            navigate(`/mall/search?keyWord=${val}`);
          }}
          onFocus={() => {
            Toast.show("获得焦点");
          }}
          onBlur={() => {
            Toast.show("失去焦点");
          }}
          onClear={() => {
            Toast.show("清空内容");
          }}
          onCancel={() => {
            Toast.show("取消搜索");
          }}
        />
      </div>
      <div className="catelog-nav-container">
        <div className={classPrefix + '-customHeader'}>
          <Tabs
            defaultActiveKey="0"
            activeKey={catelogIndex}
            onChange={onCatelogChange}
            className={'tabs'}
          >
            {catelogs?.map((item, index) => (
              <Tabs.Tab className="catelog-tab" key={index} title={item.label}></Tabs.Tab>
            ))}
          </Tabs>
          {/* <AppstoreOutline className={'moreIcon'} /> */}
          <Dropdown
            // className={classPrefix + "-dropdown"}
            className={'dropdown'}
            ref={dropdownRef}
          >
            <Dropdown.Item key="sorter" title="" >
              <div style={{ padding: "12px", textAlign: "center" }}>
                <Grid columns={4} gap={12}>
                  {catelogs.map((item, index) => (
                    <Grid.Item span={1} key={index}>
                      <Button onClick={() => {
                        onCatelogChange(String(index))
                        dropdownRef?.current?.close()
                      }} fill='none' size='mini'>
                        {item.label}
                      </Button>
                    </Grid.Item>
                  ))}
                </Grid>
              </div>
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
      <div className={classPrefix + "-sidebar-container"}>
        <SideBar
          defaultActiveKey="0"
          activeKey={sideBarKey}
          onChange={onSidebarChange}
        >
          {catelogs?.[Number(catelogIndex)]?.children ? catelogs?.[Number(catelogIndex)]?.children.map(
            (
              item: {
                value: Key | null | undefined;
                label: string | undefined;
                badge: any
              },
              index: Key | null | undefined
            ) => (
              <SideBar.Item
                key={index}
                title={<div>
                  {item.label}
                </div>}
                badge={item.badge} />
            )
          ) : null}
        </SideBar>
      </div>
      <div className="goods-list-container">
        {loading ? <><span>加载数据中</span><DotLoading color='currentColor' /></> :
          <>
            {renderIndexTabs()}
            <AutoSizer >
              {({ height, width }: { height: number, width: number }) => (
                <List
                  ref={listRef as LegacyRef<List> | undefined}
                  width={width}
                  height={height - 45}
                  scrollToAlignment={"start"}
                  rowCount={data.length}
                  rowHeight={getRowHeight}
                  rowRenderer={rowRenderer}
                  onRowsRendered={onRowsRendered}
                />
              )}
            </AutoSizer>
          </>
        }
      </div>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
        className={classPrefix + "-popup"}
        onClose={() => {
          setVisible(false)
        }}
        destroyOnClose
        position='bottom'
        bodyStyle={{
          height: '70vh',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        <div style={{ padding: '20px' }} >
          <Space direction='vertical' block>
            <Image lazy height={200} src={popupItem?.images?.[0]} fit="contain" />
            <ListView >
              {popupItem?.children?.map((child: any, index: any) => {
                return (
                  <ListView.Item
                    key={child.product_sku_id}
                    extra={(child?.saleprice ? child.saleprice : '') + '/' + child?.unit}
                    description={child?.sku_spec}
                    // clickable
                    // onClick={() => { navigate(`/m/skulist/${child.product_sku_id}`) }}
                    arrow={
                      <AddToCart
                        product={child}
                        value={props.value}
                        onChange={props.onChange}
                      />}
                  >
                    {child?.product_sku_name ? child?.product_sku_name : " "}
                  </ListView.Item>
                )
              })
              }
            </ListView>
          </Space>
        </div>
      </Popup>
    </>
  );
};

export default GoodsList;
