import request from "umi-request";
import { logout } from "../../08-Login/request";

export const GetOrderFormById = async (id?: string) => request(`/api/v2/mallorder/${id}`, {
    method: 'get',
    params: { type: "edit" },
    headers: {
        "Authorization": 'Bearer ' + localStorage.getItem("jwtToken")
    }
})
    .then(function (response) {
        return response
    })
    .catch((error) => {
        if(error?.response?.status === 401){
            logout()
        }
        return error.response
    });