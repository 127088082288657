import { Form, Input, NavBar, Radio, TextArea } from "antd-mobile"
import { useNavigate } from "react-router-dom";



const SignupForm = () => {
    const navigate = useNavigate();
    return (
        <>
            <div style={
                {
                    backgroundColor: 'rgb(250, 251, 252)',
                    height: '100vh'
                }
            }>
                <NavBar
                    style={{
                        '--border-bottom': '1px #eee solid',
                    }}
                    back='返回' onBack={() => navigate(-1)}> 用户注册 </NavBar>
                <Form layout='horizontal' mode='card'>
                    <Form.Header>用户名及密码</Form.Header>
                    <Form.Item name='username' label='用户名' required>
                        <Input placeholder='请输入' maxLength={11} />
                    </Form.Item>
                    <Form.Item name='password' label='登录密码' required>
                        <Input placeholder='请输入' maxLength={20} />
                    </Form.Item>
                    <Form.Header >信息</Form.Header>
                    <Form.Item name='phone' label='手机号'>
                        <Input placeholder='请输入' maxLength={11} />
                    </Form.Item>
                    <Form.Item name='address' label='地址' help='详细地址'>
                        <TextArea
                            showCount
                            maxLength={300}
                        />
                    </Form.Item>
                    <Form.Item name='email' label='邮箱'>
                        <Input placeholder='请输入邮箱' />
                    </Form.Item>
                    <Form.Header />
                    <Form.Header>注册邀请码</Form.Header>
                    <Form.Item label='邀请码'
                    // extra={<a>发送验证码</a>}
                    >
                        <Input placeholder='请输入' maxLength={10} />
                    </Form.Item>
                    <Form.Header>注册邀请码</Form.Header>
                    <Form.Item name='agreement'  required>
                    <Radio
                        style={{
                            '--icon-size': '18px',
                            '--font-size': '14px',
                            '--gap': '6px',
                        }}
                    >
                        用户协议<a>点击展开</a>
                    </Radio>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default SignupForm;