import { CSSProperties, useEffect, useState } from 'react'
import { List, Image, SearchBar, Toast, Empty } from 'antd-mobile'
// @ts-ignore
import { List as VirtualizedList, AutoSizer } from 'react-virtualized'
import { useNavigate } from 'react-router-dom'
import AddToCart from '../03-List/add_to_cart'
import request from 'umi-request'
import Big from 'big.js'
import { logout } from '../08-Login/request'
const classPrefix = 'trending'

type Item = {
    image: string
    product_sku_name: string
    sku_spec: string
    price: string
    unit: string
}

//历史采购过的商品及其历史采购次数， 按购买频率排序， 购买频率， 购买数量， 购买次数，购买金额
export default () => {
    const [data, setData] = useState<Item[]>()

    function onSearch(val?: any) {
        if (data?.find((item) => item.product_sku_name.includes(val))) {
            setData(data => data?.filter((item) => item.product_sku_name.includes(val)))
            Toast.show(`搜索到了${val}`)
        } else {
            // setData(data => data.filter((item) => item.name.includes(val)))
            Toast.show(`没有搜索到${val}`)
        }
    }

    function rowRenderer({
        index,
        key,
        style,
    }: {
        index: number
        key: string
        style: CSSProperties
    }) {
        const item = data?.[index]
        return (
            <List.Item
                key={key}
                style={style}
                prefix={
                    <Image
                        src={item?.image}
                        style={{ borderRadius: 20 }}
                        fit='cover'
                        width={40}
                        height={40}
                    />
                }
                extra={<AddToCart product={item} value={[]} />}
                description={`¥ ${item?.price ? Big(item?.price).toFixed() : null} / ${item?.unit} | ${item?.sku_spec}`}
            >
                {item?.product_sku_name}
            </List.Item>
        )
    }

    useEffect(() => {
        getDayliyPurchase().then((res: any) =>
            setData(res?.data)
        )
        console.log('get daily purchased')
    }, [])
    return (
        <>
            <div className={classPrefix}>
                <div className={classPrefix + '-search-bar-container'}>
                    <SearchBar
                        // ref={searchRef}
                        // defaultValue={urlParams.keyWord}
                        placeholder="搜索常用商品"
                        showCancelButton
                        style={{
                            padding: "15 12 15 12",
                        }}
                        onSearch={(val: any) => {
                            Toast.show(`你搜索了：${val}`);
                            onSearch(val)
                        }}
                        // onFocus={() => {
                        //     Toast.show("获得焦点");
                        // }}
                        // onBlur={() => {
                        //     Toast.show("失去焦点");
                        // }}
                        onClear={() => {
                            Toast.show("清空内容");
                            onSearch()
                        }}
                        onCancel={() => {
                            Toast.show("取消搜索");
                            onSearch()
                        }}
                    />
                </div>
                {data ? 
                <List header='常购商品列表'>
                    <AutoSizer  >
                        {({ width, height }: { width: number; height: number }) => (
                            <VirtualizedList
                                rowCount={data?.length}
                                rowRenderer={rowRenderer}
                                width={width}
                                height={height}
                                rowHeight={60}
                                overscanRowCount={10}
                            />
                        )}

                    </AutoSizer>
                </List>
                    : <Empty
                        style={{ padding: '64px 0' }}
                        imageStyle={{ width: 128 }}
                        description='暂无数据'
                    />}
            </div>
        </>
    )
}


const getDayliyPurchase = () =>
    request.get('/api/v2/mallproduct/daily', {
        method: "GET",
        headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
    })
        .then(res => {
            return res
        })
        .catch(err => {
            if(err?.response?.status === 401){
                logout()
            }
        })

