import request from "umi-request";
import { logout } from "../08-Login/request";

export const temploginurl = (minutes?: number, renew?: boolean) =>
  request("/api/v2/auth/qrcodeurl", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwtToken"),
    },
    params: {
      minutes: minutes,
      renew: renew
    }
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        logout();
      }
    });

export const tempQRCodelogin = (uuid?: string) =>
  request(`../api/v2/auth/templogin/${uuid}`, {
    method: "POST",
  })
    .then((res) => {
      return res;
    })

