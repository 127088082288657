import { useState, useRef, useEffect } from 'react'
import { Empty, Grid, Input, List, Stepper, SwipeAction } from 'antd-mobile'
// import GoodsList from '../goods_list'
import { sleep } from 'antd-mobile/es/utils/sleep'
import { SwipeActionRef } from 'antd-mobile/es/components/swipe-action'

import Big from 'big.js'
import { product } from '../../03-List/add_to_cart'
// 自定义内容
const GoodsSelectList = (props: any) => {
    const [goods, onChange] = useState<product[]>([]);
    const ref = useRef<SwipeActionRef>(null)

    //和父组件同步数据
    const syncOnChange = (value: product[]) => {
        onChange(value);
        props.onChange(value);
    }

    //同步父组件reset

    useEffect(() => {
        onChange(props.value ? props.value : [])
        // console.log('render goods list', props.value)
        // console.log('render goods onChange', props.onChange)
    }, [props.value])
    return (
        <>
            {/* <Mask visible={visible}
                onMaskClick={() => {
                    setAfterVisible(false);
                    setVisible(false);
                }}
                afterShow={()=>{
                    setAfterVisible(true)
                }}
                afterClose={async () => {
                    await sleep(1000)
                    if (goods) (props.onChange([...goods]));

                }}
            >
              {(afterVisible && bizpartnerid && warehouseid) &&  <GoodsList value={goods} onChange={onChange} bizpartnerid={bizpartnerid} warehouseid={warehouseid} />}
            </Mask> */}

            {goods ? goods.map((item, index: number) => {
                return (
                    <SwipeAction
                        ref={ref}
                        key={index}
                        rightActions={[
                            {
                                key: 'delete',
                                text: '删除',
                                color: 'danger',
                                onClick: async () => {
                                    ref.current?.close()
                                    await sleep(400)
                                    const newValue = [...goods]
                                    newValue.splice(index, 1)
                                    syncOnChange(newValue);
                                },
                            },
                        ]}
                    >
                        <List.Item
                            key={index}
                            // prefix={
                            //   index+1
                            // }
                            // onClick={onClick}
                            description={
                                <Grid columns={3} >
                                    <Grid.Item span={3}>
                                        <div >
                                            规格: {item.sku_spec}
                                        </div>
                                    </Grid.Item>
                                    <Grid.Item span={1}>
                                        <div >
                                            数量
                                            <Stepper
                                                value={item.quantity}
                                                min={0}
                                                digits={0}
                                                onChange={value => {
                                                    const newGoods = [...goods]
                                                    newGoods[index].quantity = value
                                                    newGoods[index].amount = Big(item.price).times(value).toFixed(2);
                                                    syncOnChange(newGoods)
                                                }}
                                            />
                                        </div>
                                    </Grid.Item>
                                    <Grid.Item span={1}>
                                        <div style={{
                                            color: 'black',
                                        }}>
                                            单价 ¥
                                            <br />
                                            <p style={{ fontSize: '16px', margin: 0 }}>¥ {item.price}</p>
                                            {/* <Stepper
                                                value={Number(item.price)}
                                                min={0}
                                                digits={2}
                                                onChange={value => {
                                                    // console.log(value)
                                                    const newGoods = [...goods]
                                                    newGoods[index].price = value
                                                    newGoods[index].amount = Big(item.quantity).times(value).toFixed(2);
                                                    syncOnChange(newGoods)
                                                }}
                                            /> */}
                                        </div>
                                    </Grid.Item>
                                    <Grid.Item span={1}>
                                        <div style={{
                                            color: 'black',
                                            fontWeight: 'bold',
                                            textAlign: 'end',
                                        }}>
                                            金额
                                            <br />
                                            <p style={{ fontSize: '16px', margin: 0 }}>¥ {item.amount}</p>
                                        </div>
                                    </Grid.Item>
                                    <Grid.Item span={3}>
                                        <span >备注: </span>
                                        <Input
                                            type="text"
                                            maxLength={5}
                                            value={item.notes}
                                            placeholder='请输入备注'
                                            clearable
                                            onChange={value => {
                                                // console.log(value)
                                                const newGoods = [...goods]
                                                newGoods[index].notes = value
                                                syncOnChange(newGoods)
                                            }}
                                        />
                                    </Grid.Item>
                                </Grid>
                            }
                        >
                            <Grid columns={3} >
                                <Grid.Item span={2}>
                                    <div >
                                        {index + 1}. {item.product_sku_name}
                                    </div>
                                </Grid.Item>
                                <Grid.Item>
                                    <div >
                                        {item.unit}
                                    </div>
                                </Grid.Item>
                            </Grid>

                        </List.Item>
                    </SwipeAction>
                )
            }) : <List.Item><Empty description='暂无商品' /></List.Item>
            }
        </>
    )
}

export default GoodsSelectList;